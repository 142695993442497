<template>
  <v-container fluid class="fill-height text-xs-center full-inner-height">
    <v-layout align-center justify-center>
      <v-flex xs12 v-if="!!loading">
        <v-progress-circular class="loading-circle center-margin" size="50" indeterminate color="orange" />
      </v-flex>
      <v-flex xs12 lg9 v-else>
        <v-card>
          <v-card-title class="flex">
            <h3><span v-html="selectedMenu.breadcrumb(menu)"></span></h3>
            <v-spacer />
            <div v-if="!menu" class="text-xs-right">
              <IconWithTooltip 
                :text="tooltip"
              />
            </div>
          </v-card-title>
          <PaymentPlanChoiceForm
            v-if="!menu"
            :items="selectableCardItems._"
            @back="isSwitchingPlan ? goBack() : $router.push(role == 'admin' ? '/admin/managerview-paymentplans' : '/owner/access-plans')"
          />
          <PaymentPlanInformationForm 
            v-else-if="['simple', 'conditional'].includes(menu)"
            v-model="inputs"
            :error="error"
            :menu="menu"
            @back="goBack()"
            @submit="next()"
          />
          <PaymentPlanPaymentForm
            v-else-if="['simple-payment', 'conditional-payment'].includes(menu)"
            v-model="inputs"
            :error="error"
            :menu="menu"
            @back="goBack()"
            @submit="next()"
          />
          <PaymentPlanChoiceForm
            v-else-if="['conditional-choice'].includes(menu)"
            :items="selectableCardItems.conditional"
            :cardClasses="['xs12', 'md12', 'lg4']"
            @back="goBack()"
          />
          <PaymentPlanConditionalDurationForm
            v-else-if="menu === 'conditional-duration'"
            v-model="inputs"
            @back="goBack()"
            @submit="next()"
          />
          <PaymentPlanConditionalConsumptionForm
            v-else-if="menu === 'conditional-consumption'"
            v-model="inputs"
            @back="goBack()"
            @submit="next()"
          />
          <PaymentPlanChoiceForm
            v-else-if="['time-based'].includes(menu)"
            :items="selectableCardItems.timeBased"
            :cardClasses="['xs12', 'md12', 'lg4']"
            @back="goBack()"
          />
          <PaymentPlanTimeBasedDateForm
            v-else-if="['time-based-date'].includes(menu)"
            v-model="inputs"
            :error="timebasedInputError"
            @back="goBack()"
            @submit="next()"
            @error="v => timebasedInputError = v"
          />
          <PaymentPlanTimeBasedDayForm
            v-else-if="['time-based-day'].includes(menu)"
            v-model="inputs"
            :error="timebasedInputError"
            @back="goBack()"
            @submit="next()"
            @error="v => timebasedInputError = v"
          />
          <PaymentPlanTimeBasedTimeForm
            v-else-if="['time-based-time'].includes(menu)"
            v-model="inputs"
            :error="error"
            :timebased-error="timebasedInputError"
            @back="goBack()"
            @submit="next()"
            @error="v => timebasedInputError = v"
          />
          <PaymentPlanConditionalPaymentForm
            v-else-if="['conditional-condition-payment'].includes(menu)"
            v-model="inputs"
            :error="error"
            @back="goBack()"
            @submit="next()"
          />
          <PaymentPlanFormSummary
            v-else-if="['simple-summary', 'conditional-summary'].includes(menu)"
            :menu="menu"
            v-model="inputs"
            :error="error"
            @switch-plan="switchPlan()"
            @edit="({ to, isConditional, indexes, type, plan, condition  }) => edit({ fromSummary: true, to, isConditional, indexes, type, plan, condition })"
            @addRule="indexes => addRule({ indexes })"
            @submit="submitPaymentPlan()"
            @error="v => error = v"
          />
        </v-card>
      </v-flex>
    </v-layout>

    <ExitConfirmationModal
      :show="leaving.show_confirmation"
      :text='`You are currently in the process of creating or editing a payment plan. Navigating away from this page will cause you to lose any changes you have made.<br /><br />Please go back and complete the creation/edit process if you would like to keep you changes.`'
      @confirmed="leaving.confirmed = true;$router.push(leaving.to);"
      @cancel="leaving.confirmed = false;leaving.to = null;leaving.show_confirmation = false;"
      @changed="(v) => leaving.show_confirmation = v"
    />
  </v-container>
</template>

<script>
import { cloneDeep, isEqual } from 'lodash-es';
import mixin from "@/mixins";
import sort from "@/mixins/sort";
import helpers from "@/mixins/helpers";
import Api from "@/library/apis/Api";
import { mapState, mapGetters } from 'vuex';
import {
  getSelectedCardItems, initializedInputs, 
  timeBasedParseConditionToInputs, allowedDates, menus, tooltips
} from './utils';
import { navigate } from './menus'
import PaymentPlanInformationForm from "./menus/Information.vue"
import PaymentPlanPaymentForm from "./menus/Payment.vue"
import PaymentPlanTimeBasedDateForm from "./menus/conditional/time-based/Date.vue"
import PaymentPlanTimeBasedDayForm from "./menus/conditional/time-based/Day.vue"
import PaymentPlanTimeBasedTimeForm from "./menus/conditional/time-based/Time.vue"
import PaymentPlanConditionalPaymentForm from "./menus/conditional/Payment.vue"
import PaymentPlanChoiceForm from "./components/ChoiceForm.vue"
import RangeChoiceForm from "./components/RangeChoiceForm.vue"
import PaymentPlanConditionalDurationForm from "./menus/conditional/Duration.vue"
import PaymentPlanConditionalConsumptionForm from "./menus/conditional/Consumption.vue"
import ExitConfirmationModal from '@/components/modals/ExitConfirmationModal.vue'
import IconWithTooltip from "@components/IconWithTooltip.vue"
import PaymentPlanFormSummary from "./menus/Summary.vue"

export default {
  name: "PaymentPlanForm",
  mixins: [mixin, sort, helpers],
  components: {
    ExitConfirmationModal, IconWithTooltip, 
    PaymentPlanFormSummary, PaymentPlanInformationForm, PaymentPlanPaymentForm,
    PaymentPlanTimeBasedDateForm, PaymentPlanTimeBasedDayForm,
    PaymentPlanTimeBasedTimeForm, PaymentPlanConditionalPaymentForm,
    PaymentPlanChoiceForm, RangeChoiceForm, PaymentPlanConditionalDurationForm,
    PaymentPlanConditionalConsumptionForm
  },
  data() {
    return {
      error: null,
      timebasedInputError: null,
      isEditTimeBasedRule: false,
      isEditFromSummary: false,
      leaving: {
        confirmed: false,
        show_confirmation: false,
        to: null,
      },
      menu: null,
      tooltip: tooltips.paymentPlan,
      inputs: {},
    };
  },
  computed: {
    ...mapState({  
      role: (state) => state.Auth.role,
      loading: state => state.loading,
      preferences: state => state.Global.preferences || {},
      managerview: (state) => state.Admin.managerview,
    }),
    ...mapGetters(["accessPlans"]),
    editId() { return +this.$route.params.id },
    selectedMenu() { return menus.find(menu => menu.slug.includes(this.menu || null)) },
    selectableCardItems() { return getSelectedCardItems(this) },
    selectedPlan() {
      const id = +this.$route.params.id;
      if (!id) return null;
      return this.accessPlans.find((plan) => plan.id === id);
    },
    isSwitchingPlan() {
      const validMenus = [null, "simple", "conditional"]; 
      return validMenus.includes(this.menu) && this.isEditFromSummary
    }
  },
  watch: {
    menu: function(value) {
      switch (value) {
        case "simple-summary":
        case "conditional-summary":
          this.isEditTimeBasedRule = false;
          this.isEditFromSummary = false;
          break;
        case "time-based":
          this.inputs.conditional.type = null;
          break;
        case "time-based-date":
          this.inputs.conditional.date.tab = 0;
          const is_from_date_empty = !this.inputs.conditional.date.fromRawDate;
          const is_to_date_empty = !this.inputs.conditional.date.toRawDate;
          if (is_from_date_empty) this.inputs.conditional.date.fromRawDate = allowedDates.min;
          if (is_to_date_empty) this.inputs.conditional.date.toRawDate = allowedDates.min;
          this.$nextTick(() => {
            if (is_from_date_empty) this.inputs.conditional.date.fromRawDate = null;
            if (is_to_date_empty) this.inputs.conditional.date.toRawDate = null;
          });
          break;
      }
    },
    "inputs.conditional.date.fromRawDate": function(val) {
      if (!val) {
        this.inputs.conditional.date.tab = 0;
        this.inputs.conditional.date.minDay = null;
        this.inputs.conditional.date.minMonth = null;
        return;
      }
      this.inputs.conditional.date.minDay = parseInt(this.$moment(val).format("DD"));
      this.inputs.conditional.date.minMonth = parseInt(this.$moment(val).format("MM"));
      this.inputs.conditional.date.tab = 1;
    },
    "inputs.conditional.date.toRawDate": function(val) {
      if (!val) {
        this.inputs.conditional.date.maxDay = null;
        this.inputs.conditional.date.maxMonth = null;
        return
      }
      this.inputs.conditional.date.maxDay = parseInt(this.$moment(val).format("DD"));
      this.inputs.conditional.date.maxMonth = parseInt(this.$moment(val).format("MM"));
    },
    selectedPlan: {
      handler(newValue, oldValue) {
        if (!isEqual(newValue, oldValue)) this.init()
      },
      deep: true
    }
  },
  methods: {
    addRule({ indexes = [] }) {
      this.reset({ only_conditional: true });
      this.inputs.conditional.unless_indexes = indexes;
      this.isEditFromSummary = true;
      this.isEditTimeBasedRule = false;
      this.menu = "conditional-choice";
    },
    edit({ fromSummary, to, isConditional = false, type, indexes, plan, condition }) {
      this.isEditFromSummary = fromSummary;
      if (to) this.menu = to;
      if (isConditional) {
        this.reset({ only_conditional: true, excludes: ['unless'] });
        this.isEditTimeBasedRule = true;
        this.inputs.conditional.unless_indexes = indexes;
        this.inputs.conditional.type = type;
        this.inputs.conditional.plan = plan;
        this.inputs.conditional[type] = timeBasedParseConditionToInputs({ condition, type });
      }
    },
    goBack() {
      if (this.isSwitchingPlan) {
        this.isEditFromSummary = false
        this.init()
        return
      }

      this.timebasedInputError = null;

      const navigateTo = navigate({
        isNext: false, 
        menu: this.menu, 
        editId: this.editId, 
        editFromSummary: this.isEditFromSummary, 
        conditionalType: this.inputs.conditional.type 
      });
      if (navigateTo === false) return this.$router.push(this.role == "admin" ? "/admin/managerview-paymentplans" : "/owner/access-plans");
      if (this.menu === "conditional-condition-payment") this.isEditTimeBasedRule = true;
      this.menu = navigateTo;
    },
    init() {
      const id = +this.$route.params.id;
      if (!id) return;
      const selectedPlan = cloneDeep(this.selectedPlan)
      if (!selectedPlan) return;
      const params = { ...selectedPlan.params, planId: id, conditional: { unless: selectedPlan.params.unless } }
      const { inputs } = initializedInputs({
        values: {
          ...cloneDeep(params || {}),
          hidePayment: (this.preferences.hiddenPayments || []).includes(id)
        }
      })
      this.inputs = inputs;
      if (Object.keys(params).length > 0) {
        this.menu = params.timezone ? "conditional-summary" : "simple-summary"
      }
    },
    next() {
      const { navigateTo, inputs, error } = navigate({
        isNext: true, 
        menu: this.menu, 
        editFromSummary: this.isEditFromSummary, 
        inputs: this.inputs,
        isEditTimeBasedRule: this.isEditTimeBasedRule,
      });
      this.menu = navigateTo;
      this.inputs = inputs;
      this.timebasedInputError = error || null;
    },
    reset({ only_conditional = false, menu = false, excludes = [] }) {
      const { inputs, conditional } = initializedInputs({ values: this.inputs, excludes })
      !only_conditional ? this.inputs = inputs : this.inputs.conditional = conditional;
      if (menu) this.menu = null;
    },
    submitPaymentPlan() {
      this.$store.dispatch("loading", true);

      let data = {
        planId: this.inputs.planId,
        params: {
          description: this.inputs.description,
          planType: "simple-plan",
          walletId: this.inputs.walletId,
          isAutoStart: this.inputs.isAutoStart,
        },
      };
      if (!this.inputs.disabled) {
        data.params.startSessionCost = parseFloat(this.inputs.startSessionCost || 0);
        data.params.hRate = parseFloat(this.inputs.hRate || 0);
        data.params.whRate = parseFloat(this.inputs.whRate || 0) / 1000;
      } else data.params.disabled = this.inputs.disabled;
      if (this.inputs.timezone) {
        data.params.timezone = this.inputs.timezone;
        if (this.inputs.conditional.unless) data.params.unless = this.inputs.conditional.unless;
      }
      
      if (this.role === "admin" && !!this.managerview) data.ownerUsername = this.managerview.owner_username; 
      const request = this.editId ? Api.plugPaymentAccessPlansUpdate(this.role, data) : Api.plugPaymentAccessPlansCreate(this.role, data);
      request
        .then((response) => {
          this.$store.dispatch("loadAccessPlans");
          this.$store.dispatch("snackbar", {
            message: this.editId ? "Payment Plan Updated" : "Payment Plan Created",
          });

          const preferences = this.managerview?.preferences || this.preferences || {}
          let hiddenPayments = preferences.hiddenPayments || []; 
          if (!this.inputs.hidePayment) hiddenPayments = hiddenPayments.filter(v => v !== this.inputs.planId)
          else if (!hiddenPayments.includes(this.inputs.planId)) hiddenPayments.push(this.inputs.planId)

          const updatedPreferences = {
            ...preferences,
              settings: {
                darkTheme: preferences.settings?.darkTheme || false
              },
            hiddenPayments
          }
          
          if (this.role === "owner") {
            Api.setPrefs("owner", { prefs: updatedPreferences }).then(() => {
              this.$store.dispatch("Global/initializePreferences");
            })
            // add reload page to refresh navigation bar warning sign at the first time create payment plans for users & managers
            if (this.role !== "admin" && !this.editId && this.accessPlans.length <= 0) { 
              setTimeout(() => {
                window.location.reload(); 
              }, 500);
            }
          }
        })
        .catch((error) => {
          this.error = error.response.data;
        })
        .finally(() => {
          this.leaving.confirmed = true;
          this.$store.dispatch("loading", false);
          
          this.$router.push(this.role == "admin" ? "/admin/managerview-paymentplans" : "/owner/access-plans");
        })
    },
    switchPlan() {
      // reset input disabled
      this.inputs.disabled = false;
      this.isEditFromSummary = true;
      this.reset({ menu: true })
    },
  },
  created() {
    const { inputs } = initializedInputs({})
    this.inputs = inputs;
  },
  mounted() {
    if (this.role === "admin" && !this.managerview) {
      this.leaving.confirmed = true
      this.$router.push("/admin/managerview");
    }
    if (this.accessPlans && this.accessPlans.length > 0) this.init() 
  },
  beforeRouteLeave: function(to, from, next) {
    if (this.leaving.confirmed) {
      next();
    } else {
      this.leaving.to = to.path;
      this.leaving.show_confirmation = true;
      this.leaving.confirmed = false;
      next(false);
    }
  },
};
</script>
