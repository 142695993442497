<template>
  <div>
    <v-card-text>
      <v-layout justify-center>
        <v-flex xs12 md9 lg6>
          <v-card class="subcard">
            <v-card-text>
              <h4 class="text-xs-left pl-4 ">FROM:</h4>
              <v-layout justify-space-around class="mt-2">
                <v-flex xs3>
                  <v-select
                    v-model="inputs.conditional.time.minHour"
                    :items="variables.hours"
                    label="HH"
                    outline
                    :rules="[rules.required]"
                    :error="!!error"
                    :disabled="loading"
                  />
                </v-flex>
                <v-flex xs3>
                  <v-select
                    v-model="inputs.conditional.time.minMinute"
                    :items="['00', '15', '30', '45']"
                    label="MM"
                    outline
                    :rules="[rules.required]"
                    :error="!!error"
                    :disabled="loading"
                  />
                </v-flex>
                <v-flex xs3>
                  <v-select
                    v-model="inputs.conditional.time.minAmPm"
                    :items="['AM', 'PM']"
                    outline
                    label="AM / PM"
                    :rules="[rules.required]"
                    :error="!!error"
                    :disabled="loading"
                  />
                </v-flex>
              </v-layout>
              <h4 class="text-xs-left pl-4 mt-4">TO:</h4>
              <v-layout justify-space-around class="mt-2">
                <v-flex xs3>
                  <v-select
                    v-model="inputs.conditional.time.maxHour"
                    :items="variables.hours"
                    label="HH"
                    outline
                    :rules="[rules.required]"
                    :error="!!error"
                    :disabled="loading"
                  />
                </v-flex>
                <v-flex xs3>
                  <v-select
                    v-model="inputs.conditional.time.maxMinute"
                    :items="['00', '15', '30', '45']"
                    label="MM"
                    outline
                    :rules="[rules.required]"
                    :error="!!error"
                    :disabled="loading"
                  />
                </v-flex>
                <v-flex xs3>
                  <v-select
                    v-model="inputs.conditional.time.maxAmPm"
                    :items="['AM', 'PM']"
                    outline
                    label="AM / PM"
                    :rules="[rules.required]"
                    :error="!!error"
                    :disabled="loading"
                  />
                </v-flex>
              </v-layout>
            </v-card-text>
          </v-card>

          <v-flex xs12 class="mt-4">
            <h3 class="mb-4 text-xs-left">
              <PaymentPlanText
                :type="inputs.conditional.type"
                :value="inputs.conditional.time"
              />
            </h3>
            <v-alert 
              :value="!!inputError"
              type="error"
              transition="slide-y-transition"
              :dismissible="true"
              @input="inputError = null"
            >
              {{ inputError }}
            </v-alert>
          </v-flex>
        </v-flex>
      </v-layout>
    </v-card-text>
    <v-card-actions class="pa-3 flex">
      <v-btn color="gray" flat="flat" @click="$emit('back')">
        Back
      </v-btn>
      <v-spacer />
      <v-btn
        color="green"
        flat="flat"
        @click="$emit('submit')"
      >
        Next
      </v-btn>
    </v-card-actions>
  </div>
</template>

<script>
import validators from "@/mixins/validators";
import { variables } from "./../../../utils"
import { timeBasedGetText } from "@/library/helpers"
import { mapState } from "vuex"
import PaymentPlanText from "@components/payment-plan/PaymentPlanText"
export default {
  name: "PaymentPlanTimeBasedDateForm",
  mixins: [validators],
  components: { PaymentPlanText },
  props: {
    value: {
      required: true,
      type: Object
    },
    error: {
      required: true
    },
    timebasedError: {
      default: null,
      required: true
    }
  },
  computed: {
    ...mapState({
      loading: state => state.loading,
    }),
    inputs: {
      get() { return this.value },
      set(v) { this.$emit("input", v) }
    },
    inputError: {
      get() { return this.timebasedError },
      set(v) { this.$emit("error", v) }
    },
    variables: () => variables
  },
  methods: {
    getText(type = "time", from = true) { 
      return timeBasedGetText({ type, from, values: this.inputs.conditional }) 
    },
  }
}
</script>