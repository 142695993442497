import { timeBasedPrepareUnless, timeBasedParseUnless } from "./../utils"


/**
 * There's 2 possible returns:
 * - Any string or null => the next menu the same as this string
 * - false (boolean) => next menu not exist and go back to prev page
 */
export function back({ menu, editId, editFromSummary, conditionalType }) {
  let navigateTo = menu;
  switch (menu) {
    case "simple":
    case "conditional":
      navigateTo = editFromSummary ? `${menu}-summary` : null 
      break;
    case "simple-summary":
    case "conditional-summary":
      navigateTo = !!editId ? false : (menu === "simple-summary" ? "simple-payment" : "conditional-payment")
      break;
    case "simple-payment":
      navigateTo = !editFromSummary ? "simple" : "simple-summary"
      break;
    case "conditional-payment":
      navigateTo = !editFromSummary ? "time-based" : "conditional-summary"
      break;
    case "conditional-choice":
      navigateTo = "conditional-summary"
      break;
    case "conditional-duration":
    case "conditional-consumption":
    case "time-based":
      navigateTo = "conditional-choice"
      break;
    case "time-based-date":
    case "time-based-day":
    case "time-based-time":
      navigateTo = "time-based";
      break;
    case "conditional-condition-payment":
      if (conditionalType) {
        if (conditionalType.includes("consumption")) navigateTo = `conditional-consumption`
        else if (conditionalType.includes("duration")) navigateTo = `conditional-duration`
        else if (["date", "time", "day"].includes(conditionalType)) navigateTo = `time-based-${conditionalType}`
      }
      break;
  }
  // if (!navigateTo && editFromSummary) navigateTo = `${}`
  return navigateTo
}

export function next({ menu, editFromSummary, inputs, isEditTimeBasedRule }) {
  let navigateTo = menu;
  let error = null;
  switch (menu) {
    case "simple":
    case "conditional":
      navigateTo = editFromSummary ? `${menu}-summary` : `${menu}-payment`
      break;
    case "simple-payment":
    case "conditional-payment":
      if (!inputs.startSessionCost) inputs.startSessionCost = 0;
      if (!inputs.hRate) inputs.hRate = 0;
      if (!inputs.whRate) inputs.whRate = 0;
      inputs.startSessionCost = inputs.startSessionCost ? parseFloat(inputs.startSessionCost) : 0;
      inputs.hRate = inputs.hRate ? parseFloat(inputs.hRate) : 0;
      inputs.whRate = inputs.whRate ? parseFloat(inputs.whRate) : 0;
      navigateTo = menu === "simple-payment" ? "simple-summary" : "conditional-summary";
      break;
    case "conditional-duration":
      let { minDuration, maxDuration } = inputs.conditional.duration

      inputs = timeBasedPrepareUnless({
        condition: { minDuration, maxDuration },
        isEdit: isEditTimeBasedRule,
        inputs
      });
      navigateTo = "conditional-condition-payment";
      break;
    case "conditional-consumption":
      const { minConsumption, maxConsumption } = inputs.conditional.consumption
      
      inputs = timeBasedPrepareUnless({
        condition: { minConsumption, maxConsumption },
        isEdit: isEditTimeBasedRule,
        inputs
      });
      navigateTo = "conditional-condition-payment";
      break;
    case "time-based-date":
      if (!inputs.conditional.date.minDay || !inputs.conditional.date.minMonth || !inputs.conditional.date.maxDay || !inputs.conditional.date.maxMonth) {
        error = `Please select a "From" and "To" date to proceed.` 
        break;
      }
      let condition = {
        minDay: inputs.conditional.date.minDay,
        minMonth: inputs.conditional.date.minMonth,
        maxDay: inputs.conditional.date.maxDay,
        maxMonth: inputs.conditional.date.maxMonth,
        
        // these are constants values
        minHour: 0,
        minMinute: 0,
        maxHour: 23,
        maxMinute: 59,
      };

      inputs = timeBasedPrepareUnless({ condition, isEdit: isEditTimeBasedRule, inputs });
      navigateTo = "conditional-condition-payment";
      break;
    case "time-based-day":
      if (
        (inputs.conditional.day.minWeekday === 0 || inputs.conditional.day.minWeekday) &&
        (inputs.conditional.day.maxWeekday === 0 || inputs.conditional.day.maxWeekday)
      ) {
        let condition = {
          minWeekday: inputs.conditional.day.minWeekday,
          maxWeekday: inputs.conditional.day.maxWeekday,

          // these are constants values
          minHour: 0,
          minMinute: 0,
          maxHour: 23,
          maxMinute: 59,
        };

        inputs = timeBasedPrepareUnless({ condition, isEdit: isEditTimeBasedRule, inputs });
        navigateTo = "conditional-condition-payment";
        break;
      }
      error = `Please select a "From" and "To" day of the week to proceed.`
      break;
    case "time-based-time":
      if (
        inputs.conditional.time.minHour &&
        inputs.conditional.time.maxHour &&
        inputs.conditional.time.minMinute &&
        inputs.conditional.time.maxMinute &&
        inputs.conditional.time.minAmPm &&
        inputs.conditional.time.maxAmPm
      ) {
        let condition = {
          minHour: parseInt(inputs.conditional.time.minHour),
          minMinute: parseInt(inputs.conditional.time.minMinute),
          maxHour: parseInt(inputs.conditional.time.maxHour),
          maxMinute: parseInt(inputs.conditional.time.maxMinute),
        };
        if (inputs.conditional.time.minAmPm == "PM" && condition.minHour < 12) condition.minHour += 12;
        else if (inputs.conditional.time.minAmPm == "AM" && condition.minHour == 12) condition.minHour = 0;
        if (inputs.conditional.time.maxAmPm == "PM" && condition.maxHour < 12) condition.maxHour += 12;
        else if (inputs.conditional.time.maxAmPm == "AM" && condition.maxHour == 12) condition.maxHour = 0;

        inputs = timeBasedPrepareUnless({ condition, isEdit: isEditTimeBasedRule, inputs });
        navigateTo = "conditional-condition-payment";
        break;
      }
      error = `Please select a "From" and "To" time to proceed.`
      break;
    case "conditional-condition-payment":
      inputs.conditional.unless = timeBasedParseUnless(
        JSON.parse(JSON.stringify(inputs.conditional.unless_indexes)),
        inputs.conditional.unless,
        inputs.conditional.plan
      );
      navigateTo = "conditional-summary";
      break;
  }
  return { navigateTo, inputs, error }
}

