import { back, next } from './navigation';

/**
 * There's 2 possible returns:
 * - Any string or null => the next menu the same as this string
 * - false (boolean) => next menu not exist and go back to prev page
 */
export function navigate({ isNext, menu, editId, editFromSummary, conditionalType, inputs, isEditTimeBasedRule }) {
  return isNext
    ? next({ menu, editFromSummary, inputs, isEditTimeBasedRule })
    : back({ menu, editId, editFromSummary, conditionalType })
}