<template>
  <div>
    <v-card-text>
      <v-flex xs12>
        <v-layout xs12 justify-center>
          <v-flex xs12 lg9>
            <v-form ref="PaymentForm" @submit="submit()" v-model="valid">
              <h3 class="mb-4 text-xs-left">
                <PaymentPlanText
                  :type="inputs.conditional.type"
                  :value="inputs.conditional[head(inputs.conditional.type.split('-'))]"
                  :restrictSame="inputs.conditional.type !== 'day'"
                  use-charge-text
                />
              </h3>
              <v-text-field
                class="mb-3"
                v-model="inputs.conditional.plan.startSessionCost"
                :label="`Activation Fee (${selectedWalletCurrency}/Session)`"
                outline
                autocomplete="false"
                required
                type="number"
                hint="Cost to start a session"
                persistent-hint
                :rules="[rules.allowZero]"
                :error="!!error"
                :disabled="loading || inputs.conditional.plan.disabled"
                @blur="formatDecimal('startSessionCost')"
                step="0.01"
                min="0"
                @keyup.enter="submit()"
                @input="v => formatToDecimal(v, 'startSessionCost')"
              />
              <v-text-field
                class="mb-3"
                v-model="inputs.conditional.plan.hRate"
                :label="`Duration Rate (${selectedWalletCurrency}/h)`"
                outline
                autocomplete="false"
                required
                type="number"
                hint="Cost to use device per hour"
                persistent-hint
                :rules="[rules.allowZero]"
                :error="!!error"
                :disabled="loading || inputs.conditional.plan.disabled"
                @blur="formatDecimal('hRate')"
                step="0.01"
                min="0"
                @keyup.enter="submit()"
                @input="v => formatToDecimal(v, 'hRate')"
              />
              <v-text-field
                class="mb-2"
                v-model="inputs.conditional.plan.whRate"
                :label="`Consumption Rate (${selectedWalletCurrency}/kWh)`"
                outline
                autocomplete="false"
                required
                type="number"
                hint="Cost per kilowatt per hour"
                persistent-hint
                :rules="[rules.allowZero]"
                :error="!!error"
                :disabled="loading || inputs.conditional.plan.disabled"
                @blur="formatDecimal('whRate')"
                step="0.01"
                min="0"
                @keyup.enter="submit()"
                @input="v => formatToDecimal(v, 'whRate')"
              />
              <v-layout flex-column items-center justify-center>
                <v-divider class="ma-auto"></v-divider>
                <strong>OR</strong>
                <v-divider class="ma-auto"></v-divider>
              </v-layout>
              <v-checkbox class="flex justify-center" color="success" hide-details v-model="inputs.conditional.plan.disabled">
                <template v-slot:label>
                  <small style="text-align:justify">
                    Disable device (i.e. No users can activate the device)
                  </small>
                </template>
              </v-checkbox>
            </v-form>
          </v-flex>
        </v-layout>
      </v-flex>
    </v-card-text>
    <v-card-actions class="pa-3 flex">
      <v-btn color="gray" flat="flat" @click="$emit('back')">
        Back
      </v-btn>
      <v-spacer />
      <v-btn color="green" flat="flat" @click="submit()">Next</v-btn>
    </v-card-actions>
  </div>
</template>

<script>
import { formatDecimal, sortByProperty, isDecimalExceedLimit, stringToDecimal } from "@/library/helpers"
import validators from "@/mixins/validators";
import { mapState } from "vuex"
import { head } from "lodash-es"
import PaymentPlanText from "@components/payment-plan/PaymentPlanText.vue"
export default {
  name: "PaymentPlanConditionalPaymentForm",
  mixins: [validators],
  components: { PaymentPlanText },
  props: {
    value: {
      required: true,
      type: Object
    },
    error: {
      default: "",
      type: String
    },
  },
  data() {
    return {
      valid: true
    }
  },
  computed: {
    ...mapState({
      loading: state => state.loading,
      wallets(state) { return (state.wallets || []).sort(sortByProperty("description")) }
    }),
    selectedWallet() { return this.wallets.find((w) => w.id === this.inputs.walletId) || null },
    selectedWalletCurrency() { return this.selectedWallet && this.selectedWallet.currency ? this.selectedWallet.currency : null },
    inputs: {
      get() { return this.value },
      set(v) { this.$emit("input", v) }
    }
  },
  methods: {
    formatDecimal: formatDecimal,
    submit() {
      if (this.$refs.PaymentForm.validate()) this.$emit("submit")
    },
    head: head,
    formatToDecimal(value, inputKey) {
      if (isDecimalExceedLimit(value, 2)) {
        const decimalNumber = stringToDecimal(value)
        this.$nextTick(() => {
          if (this.inputs.conditional.plan[inputKey] !== decimalNumber) this.inputs.conditional.plan[inputKey] = decimalNumber;
        })
      }
    }
  }
}
</script>