<template>
  <div>
    <v-card-text>
      <v-flex xs12>
        <v-layout xs12 justify-center>
          <v-flex xs12 lg9>
            <v-form ref="planInformationForm" @submit="submit()" v-model="valid">
              <h3 class="mb-4 text-xs-left">Plan Information</h3>
              <v-text-field
                class="mb-3"
                v-model="inputs.description"
                label="Description"
                outline
                autocomplete="false"
                required
                :rules="[rules.required]"
                :error="!!error"
                :disabled="loading"
                autofocus
              />

              <v-select
                v-model="inputs.walletId"
                :items="wallets"
                item-text="name"
                item-value="id"
                label="Wallet"
                outline
                no-data-text="No wallets available"
                hint="Wallet currency will determine the billing currency"
                persistent-hint
                :rules="[rules.required]"
                :error="!!error"
                :disabled="loading"
              />

              <v-autocomplete
                v-if="menu === 'conditional'"
                class="mt-4"
                v-model="inputs.timezone"
                :items="variables.timezones"
                item-text="name"
                item-value="id"
                label="Time Zone"
                outline
                no-data-text="No timezone available"
                :rules="[rules.required]"
                :error="!!error"
                :disabled="loading"
              />
              <v-select
                class="mt-4"
                :class="{ 'mb-3': !inputs.isAutoStart }"
                v-model="inputs.isAutoStart"
                :items="[{ value: false, text: 'Disabled' }, { value: true, text: 'Enabled' }]"
                item-text="text"
                item-value="value"
                outline
                label="Single User Auto Start"
                hint="Allow ability to automatically start session for single users*"
                persistent-hint
                :error="!!error"
                :disabled="loading"
                :rules="[(v) => [true, false].includes(v) || 'This field is required']"
              />
              <div class="text-xs-left" v-if="inputs.isAutoStart">
                <p>
                  *When a device is in Smart Private mode with only a single user, the user will be given the option to automatically start sessions just by plugging in.
                </p>
                <p>
                  <img src="./../../../../assets/warning-owner.png" width="16px" class="mr-2" style="position:relative;top:3px;" />
                  <span>Note that enabling this feature may lead to slight inaccuracy when small loads are connected</span>
                </p>
              </div>
              <v-autocomplete
                v-if="!!editId && role === 'owner'"
                class="mt-4 mb-3"
                v-model="inputs.hidePayment"
                :items="[{ value: false, text: 'Disabled' }, { value: true, text: 'Enabled' }]"
                item-text="text"
                item-value="value"
                outline
                label="Hide Payment Plan"
                hint="Enable this feature if you want to depricate or hide this payment plan on your account"
                persistent-hint
                :error="!!error"
                :disabled="loading"
              />
              
            </v-form>
          </v-flex>
        </v-layout>
      </v-flex>
    </v-card-text>
    <v-card-actions class="pa-3 flex">
      <v-btn color="gray" flat="flat" @click="$emit('back')">Back</v-btn>
      <v-spacer />
      <v-btn color="green" flat="flat" @click="submit()">Next</v-btn>
    </v-card-actions>
  </div>
</template>

<script>
import { mapGetters, mapState} from "vuex"
import { variables } from "./../utils"
import validators from "@/mixins/validators";
export default {
  name: "PaymentPlanInformationForm",
  mixins: [validators],
  props: {
    value: {
      required: true,
      type: Object
    },
    error: {
      default: "",
      type: String
    },
    menu: {
      required: true
    }
  },
  data() {
    return {
      valid: true 
    }
  },
  computed: {
    ...mapState({
      loading: state => state.loading,
      role: (state) => state.Auth.role,
    }),
    ...mapGetters("Wallet", {
      hiddenWallets: "hiddenWallets",
      wallets: "visibleWallets",
    }),
    editId() { return +this.$route.params.id },
    inputs: {
      get() { return this.value },
      set(v) { this.$emit("input", v) }
    },
    variables: () => variables,
  },
  methods: {
    submit() {
      if (this.$refs.planInformationForm.validate()) this.$emit("submit")
    }
  },
  mounted() {
    if (this.hiddenWallets.includes(this.inputs.walletId)) {
      this.inputs.walletId = null
    }
  }
}
</script>