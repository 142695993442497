<template>
  <div>
    <v-card-text>
      <v-flex xs12>
        <v-layout xs12 justify-center column>
          <v-flex xs12 lg9>
            <v-card class="subcard">
              <v-card-title><h4>Payment Plan Information</h4></v-card-title>
              <v-card-text>
                <v-layout>
                  <v-layout column>
                    <v-flex xs12 class="text-xs-left">
                      <p class="mb-1">
                        Description: <strong class="ml-1">{{ inputs.description }}</strong>
                      </p>
                      <p class="mb-1">
                        Currency:
                        <strong class="ml-1">{{ selectedWalletCurrency }}</strong>
                      </p>
                      <p v-if="menu === 'conditional-summary'" class="mb-1">
                        Timezone:
                        <strong class="ml-1">{{ selectedTimezoneWithOffset }}</strong>
                      </p>
                      <p class="mb-1">
                        Auto-Start Sessions For Single Users:
                        <strong class="ml-1">{{ inputs.isAutoStart ? "Enabled" : "Disabled" }}</strong>
                      </p>
                      <p
                        v-if="role == 'owner'"
                        class="mb-1"
                      > 
                        Hide Payment Plan: 
                        <strong class="ml-1">{{ inputs.hidePayment ? "Enabled" : "Disabled" }}</strong>
                      </p>
                    </v-flex>
                  </v-layout>
                  <div>
                    <v-hover>
                      <button
                        @click="$emit('edit', { to: menu === 'simple-summary' ? 'simple' : (menu === 'conditional-summary' ? 'conditional' : null) })"
                        class="button-onlyicon"
                        slot-scope="{ hover }"
                        :disabled="!allowAccess"
                      >
                        <v-scale-transition group>
                          <v-icon size="18" style="margin: 0" key="icon">edit</v-icon>
                          <span v-if="hover" class="ml-1" key="description">Edit</span>
                        </v-scale-transition>
                      </button>
                    </v-hover>

                    <v-hover>
                      <button
                        @click="show_switch_plan_confirmation = true"
                        class="button-onlyicon ml-1"
                        slot-scope="{ hover }"
                        :disabled="!allowAccess"
                      >
                        <v-scale-transition group>
                          <v-icon size="18" style="margin: 0" key="icon">shuffle</v-icon>
                          <span v-if="hover" class="ml-1" key="description">Switch Plan Type</span>
                        </v-scale-transition>
                      </button>
                    </v-hover>
                  </div>
                </v-layout>
              </v-card-text>
            </v-card>
          </v-flex>
          <v-flex xs12 lg9 class="mt-4">
            <v-card class="subcard">
              <v-card-title>
                <h4>Billing Information</h4>
                <v-spacer />
                <div v-if="['conditional-summary'].includes(menu)" class="text-xs-right">
                  <IconWithTooltip 
                    :text="time_based_tooltip"
                  />
                </div>
              </v-card-title>
              <v-card-text>
                <v-card class="subcard mb-4">
                  <v-card-text>
                    <PaymentPlanFormBillingInformation
                      :disabled="!!inputs.disabled"
                      :currency="selectedWalletCurrency"
                      :start-session-cost="inputs.startSessionCost || 0"
                      :h-rate="inputs.hRate || 0"
                      :wh-rate="inputs.whRate || 0"
                      :menu="menu"
                      @edit-clicked="() => $emit('edit', 
                        {
                          to: menu === 'simple-summary'
                            ? 'simple-payment'
                            : (menu === 'conditional-summary'
                              ? 'conditional-payment'
                              : null
                            ) 
                        }
                      )"
                    />
                  </v-card-text>
                </v-card>

                <TimeBasedBillingInformation
                  v-if="menu === 'conditional-summary'"
                  :key="timeBasedBillingKey"
                  :data="billingInformation"
                  :show-actions="allowAccess"
                  @edit="event => $emit('edit', {
                    to: 'conditional-choice',
                    indexes: event.indexes,
                    type: event.type,
                    plan: event.params.plan,
                    condition: event.params.condition,
                    isConditional: true
                  })"
                  @addNewTimeBasedRule="$emit('addRule', [])"
                  @update="onTimeBasedRuleUpdate"
                  @addSubRule="indexes => $emit('addRule', indexes)"
                />
              </v-card-text>
            </v-card>
          </v-flex>
          <v-flex v-if="!!showCheckbox" xs12 lg9 class="mt-4">
            <v-checkbox
              required
              class="flex justify-center"
              color="success"
              hide-details
              :error="!!error"
              v-model="inputs.checkbox"
              :disabled="!allowAccess"
            >
              <template v-slot:label>
                <small style="text-align:justify">
                  I have made sure it is legal to charge and re-sell electricity in my jurisdiction
                </small>
              </template>
            </v-checkbox>
          </v-flex>
        </v-layout>
      </v-flex>
    </v-card-text>
    <v-card-actions class="pa-3 flex">
      <v-spacer />
      <v-btn
        @click="submit()"
        color="green"
        flat="flat"
        :disabled="!allowAccess"
      >
        Confirm
      </v-btn>
    </v-card-actions>

    <ConfirmationModal
      title="Change Plan Type"
      :show="show_switch_plan_confirmation"
      cancelButton="Back"
      confirmButton="Continue"
      @cancel="show_switch_plan_confirmation = false"
      @confirmed="show_switch_plan_confirmation=false;$emit('switch-plan');"
    >
      <template #text>
        <v-flex class="flex flex-column items-center">
          <p>
            Changing plan type will cause you to loose all data of the current plan. Do you wish to proceed?
            <br /><br />
            If you would like to keep the current plan's setting and make modifications, please go back and click the
            <v-icon size="18" style="margin: 0" key="icon">edit</v-icon> button on the section you want to edit.
          </p>
        </v-flex>
      </template>
    </ConfirmationModal>
  </div>
</template>

<script>
import { checkConsumptionRateFilled, tooltips, variables } from './../utils';
import { v4 as uuidv } from "uuid"
import { mapState } from "vuex"
import { sortByProperty, OwnerProxyHelpers } from "@/library/helpers"
import ConfirmationModal from '@/components/modals/ConfirmationModal.vue'
import TimeBasedBillingInformation from "@components/TimeBased/TimeBasedBillingInformation.vue"
import PaymentPlanFormBillingInformation from "./../components/BillingInformation.vue"
import IconWithTooltip from "@components/IconWithTooltip.vue"

export default {
  name: "PaymentPlanFormSummary",
  components: {
    ConfirmationModal, PaymentPlanFormBillingInformation, TimeBasedBillingInformation,
    IconWithTooltip
  },
  props: {
    menu: {
      type: String,
      required: true
    },
    value: {
      type: Object,
      required: true
    },
    error: {
      required: true
    }
  },
  data() {
    return {
      timeBasedBillingKey: null,
      show_switch_plan_confirmation: false
    }
  },
  computed: {
    ...mapState({
      wallets(state) { return (state.wallets || []).sort(sortByProperty("description")) },
      role: (state) => state.Auth.role,
    }),
    time_based_tooltip: () => tooltips.timeBased,
    inputs: {
      get() { return this.value },
      set(v) { this.$emit("input", v) }
    },
    inputError: {
      get() { return this.error },
      set(v) { this.$emit("error", v) }
    },
    selectedTimezoneWithOffset() { return (variables.timezones.find((tm) => tm.id === this.inputs.timezone) || {}).name },
    showCheckbox() {
      const isConsumptionRateFilled = this.inputs.whRate && this.inputs.whRate > 0;
      if ((this.menu && this.menu.includes("simple")) || isConsumptionRateFilled) return isConsumptionRateFilled;

      return checkConsumptionRateFilled(this.inputs.conditional.unless);
    },
    billingInformation() { return { unless: this.inputs.conditional.unless, selectedWalletCurrency: this.selectedWalletCurrency } },
    selectedWallet() { return this.wallets.find((w) => w.id === this.inputs.walletId) || null },
    selectedWalletCurrency() { return this.selectedWallet && this.selectedWallet.currency ? this.selectedWallet.currency : null },
    allowAccess() {
      return OwnerProxyHelpers.isHaveAccessToFeature("PAYMENT_PLAN_EDIT") || OwnerProxyHelpers.isHaveAccessToFeature("PAYMENT_PLAN_CREATE")
    }
  },
  methods: {
    onTimeBasedRuleUpdate(new_unless) {
      this.inputs.conditional.unless = new_unless;
      this.timeBasedBillingKey = uuidv();
    },
    submit() {
      if (!!this.showCheckbox && !this.inputs.checkbox) return this.error = "Please accept the term checkbox"
      this.$emit('submit')
    }
  }
}
</script>