export function getSelectedCardItems(_this) {
  const options = {
    _: [
      {
        icon: 'east',
        title: 'Simple Plan',
        description: 'Basic plan that allows you to bill users by activation, electrical consumption or session duration',
        onClick: () => {
          _this.menu = 'simple';
          _this.inputs.timezone = null;
        }
      },
      {
        icon: "alt_route",
        iconClass: "rotate-90",
        title: "Conditional Plan",
        description: "All features of the simple plan with the ability to change prices with time, consumption and session duration",
        onClick: () => _this.menu = 'conditional'
      }
    ],
    conditional: [
      {
        icon: "schedule",
        title: "Duration",
        description: "Set prices based on the duration of a session",
        onClick: () => {
          _this.inputs.conditional.type = 'duration';
          _this.menu = 'conditional-duration';
        }
      },
      {
        icon: "bolt",
        title: "Consumption",
        description: "Set prices based on electrical consumption",
        onClick: () => {
          _this.inputs.conditional.type = 'consumption';
          _this.menu = 'conditional-consumption';
        } 
      },
      {
        icon: "today",
        title: "Time-based Conditions",
        description: "Set prices based on calendar days, weekdays or time of day",
        onClick: () => {
          _this.menu = 'time-based';
        }
      }
    ],
    duration: [
      {
        id: 'less',
        icon: "icons/less_than.png",
        iconType: 'background-image',
        iconClass: "rectangle-icon",
        description: "Duration shorter than...",
        onClick: () => {
          _this.selectedType = 'less';
        }
      },
      {
        id: 'between',
        icon: "icons/between.png",
        iconType: 'background-image',
        iconClass: "rectangle-icon",
        description: "Duration between ... and ...",
        onClick: () => {
          _this.selectedType = 'between';
        } 
      },
      {
        id: 'more',
        icon: "icons/more_than.png",
        iconType: 'background-image',
        iconClass: "rectangle-icon",
        description: "Duration longer than...",
        onClick: () => {
          _this.selectedType = 'more';
        }
      }
    ],
    consumption: [
      {
        id: 'less',
        icon: "icons/less_than.png",
        iconType: 'background-image',
        iconClass: "rectangle-icon",
        description: "Consumption less than...",
        onClick: () => {
          _this.selectedType = 'less';
        }
      },
      {
        id: 'between',
        icon: "icons/between.png",
        iconType: 'background-image',
        iconClass: "rectangle-icon",
        description: "Consumption between ... and ...",
        onClick: () => {
          _this.selectedType = 'between';
        } 
      },
      {
        id: 'more',
        icon: "icons/more_than.png",
        iconType: 'background-image',
        iconClass: "rectangle-icon",
        description: "Consumption more than...",
        onClick: () => {
          _this.selectedType = 'more';
        }
      }
    ],
    timeBased: [
      {
        icon: "date_range",
        title: "Calendar Days",
        description: "Choose a date range within the calendar year and apply payment settings to the duration.",
        onClick: () => {
          _this.inputs.conditional.type = 'date';
          _this.menu = 'time-based-date';
        }
      },
      {
        icon: "today",
        title: "Weekday",
        description: "Choose from Monday – Sunday. Payment settings will apply to these days every week.",
        onClick: () => {
          _this.inputs.conditional.type = 'day';
          _this.menu = 'time-based-day';
        } 
      },
      {
        icon: "schedule",
        title: "Time of Day",
        description: "Choose a time duration within a 24h day. Payment settings will be applied daily during the duration.",
        onClick: () => {
          _this.inputs.conditional.type = 'time';
          _this.menu = 'time-based-time';
        }
      }
    ]
  };
  return options;
}

export function checkConsumptionRateFilled(unless) {
  if (!unless || unless.length === 0) return false;
  let is_filled = false;
  for (let i = 0; i < unless.length; i++) {
    const plan = unless[i].plan;
    if (plan.whRate && plan.whRate > 0) is_filled = true;
    if (plan.unless && plan.unless.length > 0) is_filled = checkConsumptionRateFilled(plan.unless);
    if (is_filled) break;
  }
  return is_filled;
}

export function initializedInputs({ values = {}, excludes = [] }) {
  const conditional = {
    date: { tab: 0, fromRawDate: null, toRawDate: null, maxDay: null, minDay: null, maxMonth: null, minMonth: null, minHour: 0, minMinute: 0, maxHour: 23, maxMinute: 59 },
    day: { tab: 0, minWeekday: null, minHour: 0, minMinute: 0, maxWeekday: null, maxHour: 23, maxMinute: 59 },
    plan: { disabled: false, startSessionCost: null, hRate: null, whRate: null },
    time: { maxHour: null, minHour: null, maxMinute: null, minMinute: null, maxAmPm: null, minAmPm: null },
    duration: { minDuration: null, maxDuration: null },
    consumption: { minConsumption: null, maxConsumption: null },
    type: null,
    unless: values.conditional && values.conditional.unless ? values.conditional.unless : [],
    unless_indexes: [],
  }
  if (excludes.includes('unless')) conditional.unless = values.conditional && values.conditional.unless ? values.conditional.unless : []
  const inputs = {
    checkbox: false,
    description: values.description ? values.description : null,
    disabled: values.disabled ? values.disabled : null,
    hRate: values.hRate && !values.disabled ? values.hRate : null,
    isAutoStart: !!values.isAutoStart,
    hidePayment: !!values.hidePayment, 
    planId: values.planId ? values.planId : null,
    planType: values.planType ? values.planType : 'simple-plan',
    startSessionCost: values.startSessionCost && !values.disabled ? values.startSessionCost : null,
    timezone: values.timezone ? values.timezone : null,
    walletId: values.walletId ? values.walletId : null,
    whRate: values.whRate && !values.disabled ? values.whRate : null,
    conditional
  }

  return {
    inputs, conditional
  }
}
