import timezones from "@/data/timezones.json"
import { capitalize, last, first } from "lodash-es"

export const variables = {
  timezones: timezones.map((v) => ({ name: v.text, id: v.value })),
  days: ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"],
  hours: ["01", "02", "03", "04", "05", "06", "07", "08", "09", "10", "11", "12"],
}

export const allowedDates = {
  min: "2040-01-01",
  max: "2040-12-31",
}

export const menus = [
  {
    "slug": [null],
    "breadcrumb": () => "Create Plan"
  },
  {
    "slug": ["simple", "conditional"],
    "breadcrumb": (menu) => `Create Plan &gt; ${capitalize(menu)} Plan`
  },
  {
    "slug": ["simple-payment", "conditional-payment"],
    "breadcrumb": (menu) => `Create Plan &gt; ${capitalize(first(menu.split("-")))} Plan &gt; Default Payment`
  },
  {
    "slug": ["simple-summary"],
    "breadcrumb": () => "Create Plan &gt; Simple Plan &gt; Default Payment &gt; Summary"
  },

  {
    "slug": ["conditional-choice"],
    "breadcrumb": () => "Create Plan &gt; Conditional Plan &gt; Default Payment &gt; Conditional"
  },
  {
    "slug": ["conditional-duration", "conditional-consumption"],
    "breadcrumb": (menu) => `Create Plan &gt; Conditional Plan &gt; Default Payment &gt; Conditional &gt; ${capitalize(last(menu.split("-")))}`
  },
  {
    "slug": ["time-based"],
    "breadcrumb": () => "Create Plan &gt; Conditional Plan &gt; Default Payment &gt; Conditional &gt; Time-Based"
  },
  {
    "slug": ["time-based-date", "time-based-day", "time-based-time"],
    "breadcrumb": (menu) => `Create Plan &gt; Conditional Plan &gt; Default Payment &gt; Conditional &gt; Time-Based &gt; ${capitalize(last(menu.split("-")))}`
  },
  {
    "slug": ["conditional-condition-payment"],
    "breadcrumb": () =>"Create Plan &gt; Conditional Plan &gt; Default Payment &gt; Conditional &gt; Conditional Payments"
  },
  {
    "slug": ["conditional-summary"],
    "breadcrumb": () => "Create Plan &gt; Conditional Plan &gt; Default Payment &gt; Conditional &gt; Conditional Payments &gt; Summary"
  }
]

export const tooltips = {
  paymentPlan: `
    Payment plans allow you to set billing rates for different users for different devices.<br>
    Anytime you grant access to a device, users must be put on a payment plan.<br><br>

    Examples:<br>
    <ul class="ma-0 mt-2 ml-3 pa-0">
      <li class="mb-2">Multiple users can be on a single payment plan for devices.<br>These users will be charged identically when using these devices.</li>
      <li class="mb-2">A single user can have different payment plans for different devices.<br>This user will be charged depending on the plan assigned for that device.</li>
    </ul>
  `,
  timeBased: `
    Conditional billing rules:<br><br>

    <ul class="ma-0 mt-2 ml-3 pa-0">
      <li class="mb-2">Default billing values are applied when none of the conditional rules apply.</li>
      <li class="mb-2">You can have a maximum of 3 conditional rules.</li>
      <li class="mb-2">You can have a maximum of 3 sub rules in depth within a conditional rule.</li>
      <li class="mb-2">The inner most applicable sub rule will take precedence over the outer rules.</li>
      <li class="mb-2">When a device is "Disabled", the device will not be accessible by users.</li>
    </ul>
  `
}