<template>
  <div>
    <v-card-text>
      <v-layout justify-center>
        <v-flex xs12 lg9>
          <v-card class="subcard">
            <v-card-text>
              <div>
                <h4 class="text-xs-left pl-4">FROM:</h4>
                <div class="daypicker">
                  <div class="daypicker__items">
                    <div
                      @click="inputs.conditional.day.minWeekday = i_day"
                      :class="{ daypicker__item__selected: inputs.conditional.day.minWeekday === i_day }"
                      class="daypicker__item"
                      v-for="(day, i_day) in variables.days"
                      :key="i_day"
                    >
                      {{ day.substring(0, 3).toUpperCase() }}
                    </div>
                  </div>
                </div>
              </div>
              <div class="mt-4">
                <h4 class="text-xs-left pl-4">TO:</h4>
                <div class="daypicker">
                  <div class="daypicker__items">
                    <div
                      @click="inputs.conditional.day.maxWeekday = i_day"
                      :class="{ daypicker__item__selected: inputs.conditional.day.maxWeekday === i_day }"
                      class="daypicker__item"
                      v-for="(day, i_day) in variables.days"
                      :key="i_day"
                    >
                      {{ day.substring(0, 3).toUpperCase() }}
                    </div>
                  </div>
                </div>
              </div>
            </v-card-text>
          </v-card>

          <v-flex xs12 class="mt-4">
            <h3 class="mb-4 text-xs-left">
              <PaymentPlanText
                :type="inputs.conditional.type"
                :value="inputs.conditional.day"
                restrict-same
              />
            </h3>
            <v-alert 
              :value="!!inputError"
              type="error"
              transition="slide-y-transition"
              :dismissible="true"
              @input="inputError = null"
            >
              {{ inputError }}
            </v-alert>
          </v-flex>
        </v-flex>
      </v-layout>
    </v-card-text>
    <v-card-actions class="pa-3 flex">
      <v-btn color="gray" flat="flat" @click="$emit('back')">
        Back
      </v-btn>
      <v-spacer />
      <v-btn
        color="green"
        flat="flat"
        @click="$emit('submit')"
      >
        Next
      </v-btn>
    </v-card-actions>
  </div>
</template>

<script>
import { variables } from "./../../../utils"
import { timeBasedGetText } from "@/library/helpers"
import { mapState } from "vuex"
import PaymentPlanText from "@components/payment-plan/PaymentPlanText.vue"
export default {
  name: "PaymentPlanTimeBasedDateForm",
  components: { PaymentPlanText },
  props: {
    value: {
      required: true,
      type: Object
    },
    error: {
      default: "",
      type: String
    },
  },
  computed: {
    ...mapState({
      loading: state => state.loading,
    }),
    inputs: {
      get() { return this.value },
      set(v) { this.$emit("input", v) }
    },
    inputError: {
      get() { return this.error },
      set(v) { this.$emit("error", v) }
    },
    variables: () => variables
  },
  methods: {
    getText(type = "time", from = true) { 
      return timeBasedGetText({ type, from, values: this.inputs.conditional }) 
    },
  }
}
</script>