<template>
  <div>
    <v-card-text>
      <v-layout justify-center>
        <v-flex xs12 md9 lg6>
          <v-card class="subcard">
            <v-tabs v-model="inputs.conditional.date.tab" grow>
              <v-tabs-slider color="#f09000" persistent></v-tabs-slider>
              <v-tab :key="0" ripple>
                DATE FROM
              </v-tab>
              <v-tab :key="1" ripple>
                DATE TO
              </v-tab>
            </v-tabs>
            <v-tabs-items v-model="inputs.conditional.date.tab" class="pt-3">
              <v-tab-item :key="0">
                <v-date-picker
                  class="elevation-0"
                  scrollable
                  color="#f09000"
                  v-model="inputs.conditional.date.fromRawDate"
                  :min="allowedDates.min"
                  :max="allowedDates.max"
                  :allowed-dates="(val) => val != inputs.conditional.date.toRawDate"
                  no-title
                  reactive
                  :weekday-format="() => null"
                  :header-date-format="(date) => $moment(date).format('MMMM')"
                >
                </v-date-picker>
              </v-tab-item>
              <v-tab-item :key="1">
                <v-date-picker
                  class="elevation-0"
                  scrollable
                  color="#f09000"
                  v-model="inputs.conditional.date.toRawDate"
                  :min="allowedDates.min"
                  :max="allowedDates.max"
                  :allowed-dates="(val) => val != inputs.conditional.date.fromRawDate"
                  no-title
                  reactive
                  :weekday-format="() => null"
                  :header-date-format="(date) => $moment(date).format('MMMM')"
                >
                </v-date-picker>
              </v-tab-item>
            </v-tabs-items>
          </v-card>

          <v-flex xs12 class="mt-4">
            <h3 class="mb-4 text-xs-left">
              <PaymentPlanText
                :type="inputs.conditional.type"
                :value="inputs.conditional.date"
              />
            </h3>
            <v-alert 
              :value="!!inputError"
              type="error"
              transition="slide-y-transition"
              :dismissible="true"
              @input="inputError = null"
            >
              {{ inputError }}
            </v-alert>
          </v-flex>
        </v-flex>
      </v-layout>
    </v-card-text>
    <v-card-actions class="pa-3 flex">
      <v-btn color="gray" flat="flat" @click="$emit('back')">
        Back
      </v-btn>
      <v-spacer />
      <v-btn
        color="green"
        flat="flat"
        @click="$emit('submit')"
      >
        Next
      </v-btn>
    </v-card-actions>
  </div>
</template>

<script>
import { allowedDates } from "./../../../utils"
import { mapState } from "vuex"
import PaymentPlanText from "@components/payment-plan/PaymentPlanText"
export default {
  name: "PaymentPlanTimeBasedDateForm",
  components: { PaymentPlanText },
  props: {
    value: {
      required: true,
      type: Object
    },
    error: {
      default: "",
      type: String
    },
  },
  computed: {
    ...mapState({
      loading: state => state.loading,
      wallets(state) { return (state.wallets || []).sort(this.compareValues("description")) }
    }),
    inputs: {
      get() { return this.value },
      set(v) { this.$emit("input", v) }
    },
    inputError: {
      get() { return this.error },
      set(v) { this.$emit("error", v) }
    },
    allowedDates: () => allowedDates,
  },
}
</script>