<template>
  <v-layout>
    <v-layout column>
      <v-flex v-if="!disabled" xs12 class="text-xs-left">
        <h5 v-if="['conditional-summary'].includes(menu)" class="mb-2">
          Default Billing
        </h5>
        <p class="mb-1">
          Activation Fee ({{ currency }}):
          <strong class="ml-1">{{ formatCost(parseFloat(startSessionCost || 0)) }}</strong>
        </p>
        <p class="mb-1">
          Duration Rate ({{ currency }}):
          <strong class="ml-1">{{ formatRate(parseFloat(hRate || 0)) }}</strong>
        </p>
        <p class="mb-1">
          Consumption Rate ({{ currency }}):
          <strong class="ml-1">
            {{ formatRate(parseFloat(whRate || 0), "kWh") }}
          </strong>
        </p>
      </v-flex>
      <v-flex v-else xs12 align-center class="d-flex text-xs-left">
        <p class="mb-0">Device is <strong>disabled</strong> by default</p>
      </v-flex>
    </v-layout>
    <div>
      <v-hover>
        <button
          @click="$emit('edit-clicked')"
          class="button-onlyicon"
          slot-scope="{ hover }"
          :disabled="!allowAccess"
        >
          <v-scale-transition group>
            <v-icon size="18" style="margin: 0" key="icon">
              edit
            </v-icon>
            <span v-if="hover" class="ml-1" key="description">Edit</span>
          </v-scale-transition>
        </button>
      </v-hover>
    </div>
  </v-layout>
</template>

<script>
import { formatCost, formatRate, OwnerProxyHelpers } from "@/library/helpers"

export default {
  name: "PaymentPlanFormBillingInformation",
  props: {
    disabled: {
      type: Boolean,
      required: true
    },
    menu: {
      type: String,
      required: true
    },
    currency: {
      type: String,
      required: true
    },
    startSessionCost: {
      type: Number | String,
      required: true
    },
    hRate: {
      type: Number | String,
      required: true
    },
    whRate: {
      type: Number | String,
      required: true
    }
  },
  computed: {
    allowAccess() {
      return OwnerProxyHelpers.isHaveAccessToFeature("PAYMENT_PLAN_CREATE") || OwnerProxyHelpers.isHaveAccessToFeature("PAYMENT_PLAN_EDIT")
    }
  },
  methods: {
    formatCost: formatCost,
    formatRate: formatRate
  }
}
</script>