<template>
  <RangeChoiceForm
    v-model="inputs"
    :valueKey="'duration'"
    :is-validated="isValidated"
    @back="$emit('back')"
    @submit="$emit('submit')"
  >
    <template v-slot:minInput="{ error, submit }">
      <v-layout column>
        <v-layout row>
          <v-flex xs6>
            <v-text-field
              v-model="minHourInput"
              type="number"
              label="Hour(s)"
              outline
              autocomplete="false"
              required
              hide-details
              :error="!!error"
              :min="0"
              :rules="[() => !minInputError]"
              @keyup.enter="submit()"
            />
          </v-flex>
          <span class="mx-2" />
          <v-flex xs6>
            <v-select
              v-model="minDurationMinute"
              :items="['00', '15', '30', '45']"
              label="Minute(s)"
              outline
              hide-details
              :rules="[() => !minInputError]"
              :error="!!error"
            />
          </v-flex>
        </v-layout>
        <p
          v-if="minInputError"
          class="mb-0 text-xs-left red--text mt-1 caption mx-1"
        >
          {{ minInputError }}
        </p>
      </v-layout>
    </template>
    <template v-slot:maxInput="{ error, submit }">
      <v-layout column>
        <v-layout row>
          <v-flex xs6>
            <v-text-field
              v-model="maxHourInput"
              type="number"
              label="Hour(s)"
              outline
              autocomplete="false"
              required
              hide-details
              :error="!!error"
              :min="0"
              :rules="[() => !maxInputError]"
              @keyup.enter="submit()"
            />
          </v-flex>
          <span class="mx-2" />
          <v-flex xs6>
            <v-select
              v-model="maxDurationMinute"
              :items="['00', '15', '30', '45']"
              label="Minute(s)"
              outline
              hide-details
              :rules="[() => !maxInputError]"
              :error="!!error"
            />
          </v-flex>
        </v-layout>
        <p
          v-if="maxInputError"
          class="mb-0 text-xs-left red--text mt-1 caption mx-1"
        >
          {{ maxInputError }}
        </p>
      </v-layout>
    </template>
  </RangeChoiceForm>
</template>

<script>
import { hourToSeconds, minuteToSeconds, secondToHours } from "@/library/helpers"
import validators from "@/library/helpers/validators"
import { last, padStart } from 'lodash-es'
import RangeChoiceForm from "./../../components/RangeChoiceForm.vue"
export default {
  name: "PaymentPlanConditionalDurationForm",
  components: { RangeChoiceForm },
  props: {
    value: {
      required: true,
      type: Object
    },
  },
  data() {
    return {
      minHourInput: null,
      maxHourInput: null,
      showMinError: false,
      showMaxError: true
    }
  },
  computed: {
    validators: () => validators,
    selectedType() {
      return last(this.inputs.conditional.type.split("-"))
    },
    isValidated() {
      if (this.selectedType === "between") return !this.minInputError && !this.maxInputError
      if (this.selectedType === "less") return !this.maxInputError
      if (this.selectedType === "more") return !this.minInputError
      return false
    },
    inputs: {
      get() { return this.value },
      set(v) { this.$emit("input", v) }
    },
    minDuration: {
      get() {
        return secondToHours(this.inputs.conditional.duration.minDuration, true)
      },
      set(v) {
        if (!!v && v !== 0) v = v.replace(",", ".")
        this.inputs.conditional.duration.minDuration = hourToSeconds(v)
      }
    },
    minDurationHour: {
      get() {
        return secondToHours(this.inputs.conditional.duration.minDuration)
      },
      set(v) {
        if (!v) return
        this.inputs.conditional.duration.minDuration = hourToSeconds(parseInt(v)) + minuteToSeconds(this.minDurationMinute)
      }
    },
    minDurationMinute: {
      get() {
        if (!this.inputs.conditional.duration.minDuration && this.inputs.conditional.duration.minDuration !== 0) return null
        return padStart(((this.inputs.conditional.duration.minDuration / 60) % 60).toString(), 2, "0")
      },
      set(v) {
        this.inputs.conditional.duration.minDuration = hourToSeconds(this.minDurationHour) + minuteToSeconds(v)
      }
    },
    maxDuration: {
      get() {
        return secondToHours(this.inputs.conditional.duration.maxDuration, true)
      },
      set(v) {
        if (!!v && v !== 0) v = v.replace(",", ".")
        this.inputs.conditional.duration.maxDuration = hourToSeconds(v)
      }
    },
    maxDurationHour: {
      get() {
        return secondToHours(this.inputs.conditional.duration.maxDuration)
      },
      set(v) {
        if (!v) return
        this.inputs.conditional.duration.maxDuration = hourToSeconds(parseInt(v)) + minuteToSeconds(this.maxDurationMinute)
      }
    },
    maxDurationMinute: {
      get() {
        if (!this.inputs.conditional.duration.maxDuration && this.inputs.conditional.duration.maxDuration !== 0) return null
        return padStart(((this.inputs.conditional.duration.maxDuration / 60) % 60).toString(), 2, "0")
      },
      set(v) {
        this.inputs.conditional.duration.maxDuration = hourToSeconds(this.maxDurationHour) + minuteToSeconds(v)
      }
    },
    minInputError() {
      if (!this.showMinError) return false
      if ((!this.minDurationHour && this.minDurationHour !== 0) || !this.minDurationMinute) return `Fields are required`
      if (this.minDurationHour === 0 && this.minDurationMinute === "00") return `Hour and minute combination must be bigger than 1`
      if (this.minDurationHour < 0) return `Negative value are not allowed`
      if (this.minDurationHour % 1 !== 0) return `Decimals are not allowed`
      if (this.maxDuration <= this.minDuration && this.selectedType === "between") return `Number must be smaller than the right value.`
      return false
    },
    maxInputError() {
      if (!this.showMaxError) return false
      if ((!this.maxDurationHour && this.maxDurationHour !== 0) || !this.maxDurationMinute) return `Fields are required`
      if (this.maxDurationHour === 0 && this.maxDurationMinute === "00") return `Hour and minute combination must be bigger than 1`
      if (this.maxDurationHour < 0) return `Negative value are not allowed`
      if (this.maxDurationHour % 1 !== 0) return `Decimals are not allowed`
      if (this.maxDuration <= this.minDuration && this.selectedType === "between") return `Number must be greater than the left value.`
      return false
    }
  },
  watch: {
    minHourInput(v) {
      this.showMinError = true
      if (!v) return
      this.$nextTick(() => {
        this.minHourInput = parseFloat(this.minHourInput.toString().replace(/[^0-9](.*)/gi, ""));
        this.minDurationHour = this.minHourInput
      });
    },
    maxHourInput(v) {
      this.showMaxError = true
      if (!v) return
      this.$nextTick(() => {
        this.maxHourInput = parseFloat(this.maxHourInput.toString().replace(/[^0-9](.*)/gi, ""));
        this.maxDurationHour = this.maxHourInput
      });
    },
  },
  mounted() {
    this.showMinError = this.showMaxError = false
    this.minHourInput = this.minDurationHour
    this.maxHourInput = this.maxDurationHour
  }
}
</script>