<template>
  <div>
    <v-card-text>
      <v-layout 
        justify-center
        class="flex-column"
      >
        <v-flex
          xs-12
        >
          <SelectableCards 
            :items="selectableCardItems[valueKey]"
            :itemContainerClasses="['xs12', 'md12', 'lg4']"
            :selected="selectedType"
          />
        </v-flex>
        <v-flex xs12 text-xs-center>
          <v-flex
            xs12
            class="mt-4 border-top  mx-4 pt-4"
          >
            <template
              v-if="selectedType"
            >
              <v-form ref="rangeInputForm" @submit="submit">
                <div class="inputs-container mt-4 mb-3">
                    <h3 v-if="selectedType === 'between'" class="mt-3">Between</h3>
                    <slot
                      v-if="['between', 'more'].includes(selectedType)"
                      name="minInput"
                      :error="error"
                      :submit="submit"
                      :type="selectedType"
                    />
                    <h3 v-if="selectedType === 'between'" class="mt-3">and</h3>
                    <slot
                      v-if="['between', 'less'].includes(selectedType)"
                      name="maxInput"
                      :error="error"
                      :submit="submit"
                      :type="selectedType"
                    />
                </div>
              </v-form>

              <h3
                class="mb-4 text-xs-center"
              >
                <PaymentPlanText
                  :type="`${valueKey}-${selectedType}`"
                  :value="inputs.conditional[valueKey]"
                  restrict-same
                />
              </h3>
            </template>
            <v-alert 
              :value="!!error"
              type="error"
              transition="slide-y-transition"
              :dismissible="true"
              @input="error = null"
            >
              {{ error }}
            </v-alert>
          </v-flex>
        </v-flex>
      </v-layout>
    </v-card-text>
    <v-card-actions class="pa-3 flex">
      <v-btn color="gray" flat="flat" @click="$emit('back')">
        Back
      </v-btn>
      <v-spacer />
      <v-btn
        color="green"
        flat="flat"
        @click="submit"
      >
        Next
      </v-btn>
    </v-card-actions>
  </div>
</template>

<script>
import { mapState } from "vuex"
import { getSelectedCardItems } from "./../utils"
import { capitalize, last } from 'lodash-es'
import PaymentPlanText from "@components/payment-plan/PaymentPlanText.vue"
import SelectableCards from '@components/SelectableCards/SelectableCards'
export default {
  name: "RangeChoiceForm",
  components: { SelectableCards, PaymentPlanText },
  props: {
    value: {
      required: true,
      type: Object
    },
    valueKey: {
      required: true,
      type: String
    },
    isValidated: {
      default: true,
      type: Boolean
    }
  },
  data() {
    return {
      selectedType: null,
      error: null
    }
  },
  computed: {
    ...mapState({
      loading: state => state.loading,
    }),
    inputs: {
      get() { return this.value },
      set(v) { this.$emit("input", v) }
    },
    isValid() {
      if (!this.selectedType) return false;
      const value = this.inputs.conditional[this.valueKey];
      const minValue = parseFloat(value[`min${capitalize(this.valueKey)}`])
      const maxValue = parseFloat(value[`max${capitalize(this.valueKey)}`])

      const validity = {
        less: !isNaN(maxValue),
        between: !isNaN(minValue) && !isNaN(maxValue),
        more: !isNaN(minValue)
      }
      return validity[this.selectedType]
    },
    selectableCardItems() { return getSelectedCardItems(this) },
  },
  watch: {
    // Set max value of the inputs into null if "between" not selected
    selectedType() {
      if (this.selectedType) this.inputs.conditional.type = `${this.valueKey}-${this.selectedType}`
      if (this.selectedType === 'less') this.inputs.conditional[this.valueKey][`min${capitalize(this.valueKey)}`] = null
      if (this.selectedType === 'more') this.inputs.conditional[this.valueKey][`max${capitalize(this.valueKey)}`] = null
    },
    inputs: {
      handler() {
        if (this.$refs.rangeInputForm) this.$refs.rangeInputForm.validate()
      },
      deep: true
    },
  },
  methods: {
    submit() {
      if (!this.isValid) return this.error = "Error - Values are mandatory. Please enter value(s)."
      if (!this.isValidated) return
      if (this.$refs.rangeInputForm.validate()) {
        this.$emit('submit')
      }
    },
    capitalize: capitalize,
  },
  mounted() {
    if (this.inputs && this.inputs.conditional && this.inputs.conditional.type) {
      const defaultSelectedType = last(this.inputs.conditional.type.split("-"))
      if (["less", "between", "more"].includes(defaultSelectedType)) this.selectedType = defaultSelectedType
    }
  }
}
</script>

<style scoped lang="scss">
.inputs-container {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  gap: 16px;
}
</style>