<template>
  <div>
    <v-card-text>
      <v-flex xs12>
        <v-layout xs12 justify-center>
          <v-flex xs12 lg9>
            <v-form ref="paymentForm" @submit="submit()" v-model="valid">
              <h3 v-if="['conditional-payment'].includes(menu)" class="text-xs-left">When no conditional rules are applicable, I will bill users...</h3>
              <v-text-field
                ref="startSessionCost"
                class="mt-4 mb-3"
                v-model="inputs.startSessionCost"
                :label="`Activation Fee (${selectedWalletCurrency}/Session)`"
                outline
                autocomplete="false"
                required
                type="number"
                hint="Cost to start a session"
                persistent-hint
                :rules="[rules.allowZero]"
                :error="!!error"
                :disabled="loading || inputs.disabled"
                @blur="formatDecimal('startSessionCost')"
                step="0.01"
                min="0"
                @keyup.enter="submit()"
                @input="v => formatToDecimal(v, 'startSessionCost')"
              />
              <v-text-field
                class="mb-3"
                v-model="inputs.hRate"
                :label="`Duration Rate (${selectedWalletCurrency}/h)`"
                outline
                autocomplete="false"
                required
                type="number"
                hint="Cost to use device per hour"
                persistent-hint
                :rules="[rules.allowZero]"
                :error="!!error"
                :disabled="loading || inputs.disabled"
                @blur="formatDecimal('hRate')"
                step="0.01"
                min="0"
                @keyup.enter="submit()"
                @input="v => formatToDecimal(v, 'hRate')"
              />
              <v-text-field
                class="mb-2"
                v-model="inputs.whRate"
                :label="`Consumption Rate (${selectedWalletCurrency}/kWh)`"
                outline
                autocomplete="false"
                required
                type="number"
                hint="Cost per kilowatt per hour"
                persistent-hint
                :rules="[rules.allowZero]"
                :error="!!error"
                :disabled="loading || inputs.disabled"
                @blur="formatDecimal('whRate')"
                step="0.01"
                min="0"
                @keyup.enter="submit()"
                @input="v => formatToDecimal(v, 'whRate')"
              />
              <template v-if="['conditional-payment'].includes(menu)">
                <v-layout flex-column items-center justify-center>
                  <v-divider class="ma-auto"></v-divider>
                  <strong>OR</strong>
                  <v-divider class="ma-auto"></v-divider>
                </v-layout>
                <v-checkbox class="flex justify-center" color="success" hide-details v-model="inputs.disabled">
                  <template v-slot:label>
                    <small style="text-align:justify">
                      Disable device (i.e. No users can activate the device)
                    </small>
                  </template>
                </v-checkbox>
              </template>
            </v-form>
          </v-flex>
        </v-layout>
      </v-flex>
    </v-card-text>
    <v-card-actions class="pa-3 flex">
      <v-btn color="gray" flat="flat" @click="$emit('back')">
        Back
      </v-btn>
      <v-spacer />
      <v-btn color="green" flat="flat" @click="submit()">Next</v-btn>
    </v-card-actions>
  </div>
</template>

<script>
import { formatDecimal, sortByProperty, stringToDecimal, isDecimalExceedLimit } from "@/library/helpers"
import validators from "@/mixins/validators";
import { mapState } from "vuex"
export default {
  name: "PaymentPlanPaymentForm",
  mixins: [validators],
  props: {
    value: {
      required: true,
      type: Object
    },
    error: {
      default: "",
      type: String
    },
    menu: {
      required: true
    }
  },
  data() {
    return {
      valid: true
    }
  },
  computed: {
    ...mapState({
      loading: state => state.loading,
      wallets(state) { return (state.wallets || []).sort(sortByProperty("description")) }
    }),
    selectedWallet() { return this.wallets.find((w) => w.id === this.inputs.walletId) || null },
    selectedWalletCurrency() { return this.selectedWallet && this.selectedWallet.currency ? this.selectedWallet.currency : null },
    inputs: {
      get() { return this.value },
      set(v) { this.$emit("input", v) }
    }
  },
  methods: {
    formatDecimal: formatDecimal,
    submit() {
      if (this.$refs.paymentForm.validate()) this.$emit("submit")
    },
    formatToDecimal(value, inputKey) {
      if (isDecimalExceedLimit(value, 2)) {
        const decimalNumber = stringToDecimal(value)
        this.$nextTick(() => {
          if (this.inputs[inputKey] !== decimalNumber) this.inputs[inputKey] = decimalNumber;
        })
      }
    }
  },
}
</script>