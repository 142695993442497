<template>
  <RangeChoiceForm
    v-model="inputs"
    :valueKey="'consumption'"
    @back="$emit('back')"
    @submit="$emit('submit')"
  >
    <template v-slot:minInput="{ error, submit, type }">
      <v-text-field
        v-model="minConsumption"
        type="number"
        label="kWh"
        outline
        autocomplete="false"
        required
        :min="1"
        :rules="[validators.noDecimalNumber, validators.oneOrMore, (v) => type === 'between' && maxConsumption ? validators.leftNumber(v, maxConsumption) : true]"
        :error="!!error"
        @keyup.enter="submit()"
      />
    </template>
    <template v-slot:maxInput="{ error, submit, type }">
      <v-text-field
        v-model="maxConsumption"
        type="number"
        label="kWh"
        outline
        autocomplete="false"
        required
        :min="1"
        :rules="[validators.noDecimalNumber, validators.oneOrMore, (v) => type === 'between' && maxConsumption ? validators.rightNumber(v, minConsumption) : true]"
        :error="!!error"
        @keyup.enter="submit()"
      />
    </template>
  </RangeChoiceForm>
</template>

<script>
import { kwhToWh, whToKwh } from "@/library/helpers"
import validators from "@/library/helpers/validators"
import RangeChoiceForm from "../../components/RangeChoiceForm.vue"
export default {
  name: "PaymentPlanConditionalConsumptionForm",
  components: { RangeChoiceForm },
  props: {
    value: {
      required: true,
      type: Object
    },
  },
  computed: {
    inputs: {
      get() { return this.value },
      set(v) { this.$emit("input", v) }
    },
    minConsumption: {
      get() {
        return whToKwh(this.inputs.conditional.consumption.minConsumption)
      },
      set(v) {
        this.inputs.conditional.consumption.minConsumption = kwhToWh(v)
      }
    },
    maxConsumption: {
      get() {
        return whToKwh(this.inputs.conditional.consumption.maxConsumption)
      },
      set(v) {
        this.inputs.conditional.consumption.maxConsumption = kwhToWh(v)
      }
    },
    validators: () => validators
  },
}
</script>