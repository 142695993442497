<template>
  <account-container :busy="busy" :status="status" :logo="false" v-if="role == 'owner'">
    <v-layout column>
      <v-card>
        <v-card-title>
          <img src="../../assets/PlugzioLogoHorizontal.png" height="26" style="margin-bottom: -2px" />
          <v-spacer></v-spacer>
          <h3>Device Setup</h3>
        </v-card-title>
        <v-alert :value="error" type="error" dismissable>{{ error }}</v-alert>
        <div v-if="busy">{{ status }}</div>
        <div v-else-if="invalidToken === true">
          <v-card-text>
            <div class="text-xs-center mb-3 mt-1">
              <h3>Invalid Bluetooth Token</h3>
            </div>
            <ul class="text-xs-justify">
              <li class="mb-3">The secret token you have entered is invalid for this device.</li>
              <li class="mb-3">
                This token should be provided to you with the device you purchased or if the device is already registered, the token can be found on the dashboard's
                <strong>
                  <a class="plugzio-color--text" @click.stop.prevent="$router.push({ name: `${modeRoute}advance-settings` })">Advance Settings</a>
                </strong>
                page.
              </li>
              <li>
                If you've lost this token please
                <strong> <a class="plugzio-color--text" :href="contactUs" target="_blank">contact us</a> </strong>.
              </li>
            </ul>
          </v-card-text>
          <v-card-actions class="pa-3">
            <v-spacer />
            <v-btn
              color="green darken-1"
              flat
              @click="
                invalidToken = false;
                reset();
              "
              >TRY AGAIN</v-btn
            >
          </v-card-actions>
        </div>
        <div v-else-if="deviceDisconnected && token && !deviceConnected">
          <v-card-text>
            <div class="text-xs-center mb-3 mt-1">
              <h3>You Were Disconnected</h3>
            </div>
            <disconnected-info />
          </v-card-text>
          <v-card-actions class="pa-3">
            <v-spacer />
            <v-btn color="green darken-1" flat @click="reset()">CONNECT</v-btn>
          </v-card-actions>
        </div>
        <div v-else-if="deviceConnected">
          <v-card-text>
            <div class="text-xs-center mb-3 mt-1">
              <h3 class="success--text">Your Device Is Now Connected!</h3>
            </div>
            <div class="text-xs-justify">
              Your Plugzio device has connected to a network. You can now configure your device through the
              <strong> <a class="plugzio-color--text" @click.stop.prevent="$router.push({ name: `${modeRoute}dashboard` })">Dashboard</a>. </strong>
            </div>
          </v-card-text>
          <v-card-actions class="pa-3">
            <v-btn color="green darken-1" flat @click.stop.prevent="reset()">SETUP ANOTHER DEVICE</v-btn>
            <v-spacer />
            <v-btn color="green darken-1" flat @click.stop.prevent="$router.push({ name: `${modeRoute}dashboard` })">GO TO DASHBOARD</v-btn>
          </v-card-actions>
        </div>
        <div v-else-if="connected && bleDevice && invalidToken === false">
          <div class="owner-login-header setup-header white--text pt-3 pb-3">
            <small>Paired To:</small>
            <h2>{{ bleDevice.name }}</h2>
          </div>
          <v-card-text class="pt-0 pb-0">
            <div v-if="setupMode">
              <v-card class="mt-3 mb-0 pb-0 elevation-0 setup-box" v-if="setupMode === 'add'">
                <v-card-text class="text-xs-center">
                  <strong class="text-uppercase">Add New WiFi Network</strong>
                </v-card-text>
                <v-divider></v-divider>
                <v-card-text v-if="!addWifiSuccess">
                  <v-form ref="addWifiForm" @submit="showAddWifiDialog()" v-model="validWifi">
                    <v-text-field
                      outline
                      label="SSID"
                      v-model="wifi.ssid"
                      :error="!!error"
                      autocomplete="false"
                      :rules="[rules.required]"
                      required
                      :disabled="bleBusy"
                    ></v-text-field>
                    <v-select
                      v-model="wifi.security"
                      :items="security"
                      item-text="name"
                      item-value="data"
                      label="Security"
                      outline
                      :rules="[rules.allowZero]"
                      :error="!!error"
                      :disabled="bleBusy"
                    ></v-select>
                    <v-select
                      v-if="wifi.security !== 0"
                      v-model="wifi.encryption"
                      :items="encryption"
                      item-text="name"
                      item-value="data"
                      label="Encryption"
                      outline
                      :rules="[rules.allowZero]"
                      :error="!!error"
                      :disabled="bleBusy"
                    ></v-select>
                    <v-select v-model="wifi.channel" :items="channel" label="Channel" outline :rules="[rules.allowZero]" :error="!!error" :disabled="bleBusy"></v-select>
                    <v-text-field
                      v-if="wifi.security !== 0"
                      outline
                      label="Password"
                      :append-icon="show ? 'visibility' : 'visibility_off'"
                      v-model="wifi.password"
                      :error="!!error"
                      :type="show ? 'text' : 'password'"
                      autocomplete="false"
                      :rules="[rules.required]"
                      @click:append="show = !show"
                      required
                      :disabled="bleBusy"
                    ></v-text-field>
                    <v-text-field
                      v-if="wifi.security !== 0"
                      outline
                      label="Confirm Password"
                      :append-icon="show ? 'visibility' : 'visibility_off'"
                      v-model="wifi.password2"
                      :error="!!error || wifi.password !== wifi.password2"
                      :type="show2 ? 'text' : 'password'"
                      autocomplete="false"
                      :rules="[rules.required]"
                      @click:append="show2 = !show2"
                      required
                      :disabled="bleBusy"
                    ></v-text-field>
                  </v-form>
                </v-card-text>
                <v-card-text v-else>
                  <div class="text-xs-center mb-2">
                    <h3 class="success--text">WiFi Network Added</h3>
                  </div>
                  <div class="text-xs-justify">
                    Plugzio device will now automatically attempt to connect to the network
                    <br />
                    <br />List of stored networks:
                    <br />
                    <br />
                    <div v-if="bleBusy" class="grey--text">Fetching...</div>
                    <div v-else-if="bleResponseObj && bleResponseObj.data && bleResponseObj.data.length">
                      <ul>
                        <li v-for="(wifi, index) in bleResponseObj.data" :key="index">
                          <span v-if="wifi">
                            <strong>{{ wifi[5] }}</strong>
                            <span class="ml-2 grey--text">
                              ({{ wifi[4] === 0 ? "Unsecured" : "Secured" }})
                              <span v-if="wifi[5] === 'Plugzio'">[Default]</span>
                            </span>
                          </span>
                        </li>
                      </ul>
                    </div>
                    <div v-else class="grey--text">Could not retrieve WiFi networks...</div>
                  </div>
                </v-card-text>
              </v-card>
              <v-card class="mt-3 mb-0 pb-0 elevation-0 setup-box" v-else>
                <v-card-text class="text-xs-center">
                  <strong class="text-uppercase">Clear All Stored WiFi Networks</strong>
                </v-card-text>
                <v-divider></v-divider>
                <v-card-text v-if="clearWifiSuccess">
                  <div class="text-xs-center mb-2">
                    <h3 class="success--text">Success</h3>
                  </div>
                  <div class="text-xs-justify">
                    All WiFi networks have been cleared from the device. Only the default WiFi network credentials remain. List of stored networks:
                    <br />
                    <br />
                    <ul>
                      <li>
                        <span>
                          <strong>Plugzio</strong>
                          <span class="ml-2 grey--text">(Secured) [Default]</span>
                        </span>
                      </li>
                    </ul>
                    <br />The default network listed above is programmed onto the device for easy and convenient set up. This allows you to configure a router or even hot spot your
                    phone to allow the device to connect to the internet. For security purposes, we do not advise using the default network’s credentials as a permanent connection
                    to the internet. To set up a secure connection select the following options:
                    <br />
                    <div>
                      <v-btn
                        class="plugzio-button mb-2 mt-4 wifi-setup-button"
                        block
                        outline
                        round
                        @click.stop.prevent="
                          setupMode = 'add';
                          addWifiSuccess = false;
                          clearWifiSuccess = false;
                        "
                        >Bluetooth</v-btn
                      >
                      <v-btn block class="wifi-setup-button" outline round disabled>The Dashboard (Coming Soon)</v-btn>
                    </div>
                    <br />Default credentials are:
                    <br />
                    <br />
                    <span class="ml-3">
                      Name:
                      <strong>Plugzio</strong>
                    </span>
                    <br />
                    <span class="ml-3">
                      Password:
                      <strong>WelcomePlugzio</strong>
                    </span>
                  </div>
                </v-card-text>
                <v-card-text v-else>
                  <div class="text-xs-center mb-2">
                    <h3 class="red--text">Warning</h3>
                  </div>
                  <div class="text-xs-justify">
                    This will permanently delete and remove all stored WiFi networks on the device. Once the networks are deleted, the device will no longer attempt to connect to
                    the deleted networks on the next reboot or power cycle. This action cannot be undone and networks will have to be manually added back to the device.
                    <br />
                    <br />List of stored networks:
                    <br />
                    <br />
                    <div v-if="bleBusy" class="grey--text">Fetching...</div>
                    <div v-else-if="bleResponseObj && bleResponseObj.data && bleResponseObj.data.length">
                      <ul>
                        <li v-for="(wifi, index) in bleResponseObj.data" :key="index">
                          <span v-if="wifi">
                            <strong>{{ wifi[5] }}</strong>
                            <span class="ml-2 grey--text">
                              ({{ wifi[4] === 0 ? "Unsecured" : "Secured" }})
                              <span v-if="wifi[5] === 'Plugzio'">[Default]</span>
                            </span>
                          </span>
                        </li>
                      </ul>
                    </div>
                    <div v-else class="grey--text">No WiFi networks stored</div>
                    <br />Are you sure you want to proceed?
                  </div>
                </v-card-text>
              </v-card>
            </div>
            <div v-else>
              <v-btn class="plugzio-button mb-2 mt-4 wifi-setup-button" block outline round @click.stop.prevent="setupMode = 'add'">Add New WiFi Network</v-btn>
              <v-btn
                block
                class="plugzio-button wifi-setup-button"
                outline
                round
                @click.stop.prevent="
                  newCommand(1);
                  setupMode = 'clear';
                "
                >Clear All Stored WiFi Networks</v-btn
              >
            </div>
          </v-card-text>
          <v-card-actions class="pa-3" v-if="clearWifiSuccess || addWifiSuccess">
            <v-btn
              v-if="addWifiSuccess"
              flat
              @click.stop.prevent="
                addWifiSuccess = false;
                clearWifiSuccess = false;
                setupMode = null;
              "
              >BACK TO MENU</v-btn
            >
            <v-btn
              v-else
              flat
              @click.stop.prevent="
                addWifiSuccess = false;
                clearWifiSuccess = false;
              "
              >BACK</v-btn
            >
            <v-spacer />
            <v-btn color="green darken-1" flat @click.stop.prevent="reset()">SETUP ANOTHER DEVICE</v-btn>
          </v-card-actions>
          <v-card-actions class="pa-3" v-else>
            <v-btn :disabled="!token" flat @click.stop.prevent="setupMode ? (setupMode = null) : reset()">BACK{{ setupMode ? " TO MENU" : null }}</v-btn>
            <v-spacer />
            <v-btn v-if="setupMode === 'add'" color="green darken-1" flat @click="showAddWifiDialog()" :disabled="bleBusy" :loading="bleBusy">NEXT</v-btn>
            <v-btn v-if="setupMode === 'clear'" color="green darken-1" flat @click.stop.prevent="clearWifi()" :disabled="bleBusy" :loading="bleBusy">CONFIRM</v-btn>
          </v-card-actions>
        </div>
        <div v-else-if="userCancelError && deviceNotListedDialog">
          <v-card-text>
            <div class="text-xs-center mb-3 mt-1">
              <h3>My Plugzio Device Was Not Listed</h3>
            </div>
            <disconnected-info />
          </v-card-text>
          <v-card-actions class="pa-3">
            <v-spacer />
            <v-btn
              color="green darken-1"
              flat
              @click="
                deviceNotListedDialog = false;
                userCancelError = false;
              "
              >TRY AGAIN</v-btn
            >
          </v-card-actions>
        </div>
        <div v-else>
          <v-card-text :class="{ 'opacity-50': bleBusy }">
            <v-form ref="setupForm" v-model="valid">
              <v-text-field
                outline
                label="Secret Token"
                ref="tokenField"
                v-model="token"
                :error="!!error"
                autocomplete="false"
                :rules="[rules.required]"
                @keyup.enter.stop="setup()"
                :disabled="bleBusy"
                required
              >
                <template v-slot:append>
                  <v-fade-transition leave-absolute>
                    <v-progress-circular v-if="busy" size="24" color="info" indeterminate></v-progress-circular>
                    <qr-scanner @captured="captured" />
                  </v-fade-transition>
                </template>
              </v-text-field>
            </v-form>
            <div class="text-xs-justify">
              * This token should be provided to you with the device you purchased or if the device is already registered, the token can be found on the dashboard's
              <strong>
                <a class="plugzio-color--text" @click.stop.prevent="$router.push({ name: `${modeRoute}advance-settings` })">Advance Settings</a>
              </strong>
              page.
              <br />
              <br />If you've lost this token please <strong> <a class="plugzio-color--text" :href="contactUs" target="_blank">contact us</a> </strong>.
            </div>
          </v-card-text>
          <v-card-text class="bluefy pb-0 text-xs-justify" v-if="isIOS">
            IMPORTANT: Setting up a device requires a browser that supports web bluetooth. We suggest using the Bluefy web browser for iPhones. Please download Bluefy from the App
            Store.
            <br />
            <a class="d-block text-xs-center" target="_blank" href="https://apps.apple.com/ca/app/bluefy-web-ble-browser/id1492822055">
              <img src="./../../assets/appstore.png" width="160px" />
            </a>
          </v-card-text>
          <v-card-actions class="pa-3">
            <v-btn v-if="userCancelError" class="plugzio-button" outline round @click="deviceNotListedDialog = true">My device was not listed</v-btn>
            <v-spacer />
            <v-btn :disabled="!token" class="plugzio-button" outline round :loading="busy || bleBusy" @click="permissionDialog = true">CONNECT</v-btn>
          </v-card-actions>
        </div>
      </v-card>
    </v-layout>
    <v-dialog v-model="permissionDialog" max-width="400">
      <v-card>
        <v-card-text>
          <v-icon size="42" color="#0a3d91" style="float: left" class="mr-2">bluetooth</v-icon>Bluetooth is needed to connect to the device. Please allow the app to turn on and use
          Bluetooth.
        </v-card-text>
        <v-card-actions>
          <template>
            <v-btn color="gray" flat @click="permissionDialog = false">DENY</v-btn>
            <v-spacer></v-spacer>
            <v-btn color="green darken-1" flat :loading="busy" @click="setup()">ALLOW</v-btn>
          </template>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="addWifiDialog" max-width="400">
      <v-card>
        <v-card-text>
          The device will automatically attempt to search for and connect to any newly added network. When connected to the internet, bluetooth will not be available and the device
          must be configured through the dashboard.
        </v-card-text>
        <v-card-actions>
          <template>
            <v-btn color="gray" flat @click="addWifiDialog = false">CANCEL</v-btn>
            <v-spacer></v-spacer>
            <v-btn color="green darken-1" flat :loading="busy" @click="addWifi()">OK</v-btn>
          </template>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="alertDialog" max-width="320">
      <v-card>
        <v-card-text>{{ alertMessage }}</v-card-text>
        <v-card-actions>
          <template>
            <v-btn
              v-if="allowRetry"
              color="gray"
              flat
              @click="
                allowRetry = false;
                alertDialog = false;
              "
              >CANCEL</v-btn
            >
            <v-spacer></v-spacer>
            <v-btn
              v-if="allowRetry"
              color="green darken-1"
              flat
              :loading="busy"
              @click="
                allowRetry = true;
                retry();
              "
              >RETRY</v-btn
            >
            <v-btn
              v-else
              color="gray"
              flat
              @click="
                allowRetry = false;
                alertDialog = false;
              "
              >OK</v-btn
            >
          </template>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </account-container>
</template>

<script>
import mixin from "@/mixins";
import AccountContainer from "@/containers/Account";
import QrScanner from "@/components/modals/QrScanner";
import DisconnectedInfo from "@/components/DisconnectedInfo";
let platform = require("platform");

export default {
  mixins: [mixin],
  components: {
    AccountContainer,
    QrScanner,
    DisconnectedInfo,
  },
  data() {
    return {
      token: "",
      invalidToken: null,
      deviceConnected: false,
      deviceDisconnected: false,
      bleDevice: null,
      nusService: null,
      rxCharacteristic: null,
      txCharacteristic: null,
      connected: false,
      permission: false,
      permissionDialog: false,
      setupMode: null,
      storedWifis: [],
      addWifiSuccess: false,
      addWifiCommand: null,
      addWifiDialog: false,
      clearWifiSuccess: false,
      bleResponseLog: null,
      bleResponse: null,
      bleResponseObj: null,
      ble: {
        nusServiceUUID: `6e400001-b5a3-f393-e0a9-e50e24dcca9e`,
        nusCharRXUUID: `6e400002-b5a3-f393-e0a9-e50e24dcca9e`,
        nusCharTXUUID: `6e400003-b5a3-f393-e0a9-e50e24dcca9e`,
        MTU: 20,
      },
      wifi: {
        ssid: "",
        security: null,
        encryption: 0,
        channel: null,
        password: "",
        password2: "",
      },
      security: [
        {
          name: "Unsecured",
          data: 0,
        },
        {
          name: "WEP",
          data: 1,
        },
        {
          name: "WPA-Personal",
          data: 2,
        },
        {
          name: "WPA2-Personal",
          data: 3,
        },
        {
          name: "WPA Enterprise",
          data: 4,
        },
        {
          name: "WPA2 Enterprise",
          data: 5,
        },
      ],
      encryption: [
        {
          name: "AES",
          data: 0,
        },
        {
          name: "TKIP",
          data: 1,
        },
        {
          name: "AES/TKIP",
          data: 2,
        },
      ],
      channel: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11],
      responseCodes: [
        {
          code: "COMMAND_STATUS_OK",
          message: "",
        },
        {
          code: "COMMAND_STATUS_INVALID_TOKEN",
          message: "Error: Invalid BLE Token provided. Error code 1",
        },
        {
          code: "COMMAND_STATUS_INVALID_JSON",
          message: "Error: Invalid JSON format. Error code 2",
        },
        {
          code: "COMMAND_STATUS_INVALID_BLE_COMMAND",
          message: "Error: Invalid BLE command. Error code 3",
        },
        {
          code: "COMMAND_STATUS_INVALID_DATA_COMMAND",
          message: "Error: Invalid data command. Error code 4",
        },
        {
          code: "COMMAND_STATUS_WIFI_NOT_CONNECTED",
          message: "Error: Unexpected response. Error code 5",
        },
        {
          code: "COMMAND_STATUS_WIFI_NO_CREDENTIALS",
          message: "Error: Unexpected response. Error code 6",
        },
        {
          code: "COMMAND_STATUS_WIFI_FAILED_TO_CLEAR_CREDENTIALS",
          message: "Error: Failed to clear wifi credentials. Error code 7",
        },
      ],
      busy: false,
      bleBusy: false,
      status: null,
      error: false,
      bleError: false,
      userCancelError: false,
      alertMessage: "",
      alertDialog: false,
      deviceNotListedDialog: false,
      allowRetry: false,
      lastCommand: null,
      valid: true,
      validWifi: true,
      show: false,
      show2: false,
    };
  },
  computed: {
    addWifiData() {
      let data = {
        token: this.token,
        command: 0,
        data: {
          ssid: this.wifi.ssid,
          password: String(this.wifi.password),
          securityType: this.wifi.security,
          securityCipher: this.wifi.encryption,
          channel: this.wifi.channel,
        },
      };
      return data;
    },
    isIOS() {
      if (platform && platform.os) {
        let os = platform.os.toString();
        return !!os.match(/iOS/);
      }
      return false;
    },
  },
  methods: {
    captured(evt) {
      evt && evt !== "clear" ? (this.token = evt) : (this.token = null);
      if (this.token != null) {
        this.permissionDialog = true;
      }
    },
    setup(start = true) {
      let _this = this;
      this.permissionDialog = false;
      this.error = false;
      this.status = null;
      this.deviceDisconnected = false;

      let connect = () => {
        if (!navigator.bluetooth) {
          _this.error = "WebBluetooth API is not available on your browser. Please make sure the Web Bluetooth flag is enabled.";
          return;
        }
        _this.busy = true;
        _this.setStatus("Requesting Bluetooth Device...");
        navigator.bluetooth
          .requestDevice({
            filters: [{ namePrefix: "Plugzio-" }],
            optionalServices: [_this.ble.nusServiceUUID],
          })
          .then((device) => {
            _this.bleDevice = device;
            _this.setStatus("Found " + device.name);
            // _this.setStatus('Connecting to GATT Server...')
            _this.setStatus("Connecting.");
            _this.bleDevice.addEventListener("gattserverdisconnected", _this.onDisconnected);
            return device.gatt.connect();
          })
          .then((server) => {
            // _this.setStatus('Locate NUS service')
            // _this.setStatus('Connecting..')
            return server.getPrimaryService(_this.ble.nusServiceUUID);
          })
          .then((service) => {
            _this.nusService = service;
            // _this.setStatus('Found NUS service: ' + service.uuid)
            _this.setStatus("Connecting..");
          })
          .then(() => {
            // _this.setStatus('Locate RX characteristic')
            return _this.nusService.getCharacteristic(_this.ble.nusCharRXUUID);
          })
          .then((characteristic) => {
            _this.rxCharacteristic = characteristic;
            // _this.setStatus('Found RX characteristic')
            _this.setStatus("Connecting...");
          })
          .then(() => {
            // _this.setStatus('Locate TX characteristic')
            return _this.nusService.getCharacteristic(_this.ble.nusCharTXUUID);
          })
          .then((characteristic) => {
            _this.txCharacteristic = characteristic;
            // _this.setStatus('Found TX characteristic')
            _this.setStatus("Connecting....");
          })
          .then(() => {
            // _this.setStatus('Enable notifications')
            return _this.txCharacteristic.startNotifications();
          })
          .then(() => {
            _this.setStatus("Connecting.....");
            _this.txCharacteristic.addEventListener("characteristicvaluechanged", _this.handleNotifications);
            _this.setStatus(_this.bleDevice.name + " Connected.");
            _this.setState(true);
            _this.userCancelError = false;
            _this.checkToken();
          })
          .catch((error) => {
            _this.busy = false;
            this.$nextTick(() => {
              _this.setStatus("" + error);
              if (String(error).match(/User cancelled/gi)) {
                _this.userCancelError = true;
              } else {
                _this.error = error;
              }
              if (_this.bleDevice && _this.bleDevice.gatt.connected) {
                _this.bleDevice.gatt.disconnect();
              }
            });
          })
          .finally(() => {});
      };

      let disconnect = () => {
        this.deviceDisconnected = false;
        if (!_this.bleDevice) {
          _this.setStatus("No Bluetooth Device connected...");
          return;
        }
        _this.setStatus("Disconnecting from Bluetooth Device...");
        if (_this.bleDevice.gatt.connected) {
          _this.bleDevice.gatt.disconnect();
          // _this.connected = false
          _this.token = null;
          _this.setupMode = "token";
          _this.setState(false);
          _this.setStatus("Bluetooth Device disconnected: " + _this.bleDevice.gatt.connected);
          try {
            delete _this.bleDevice;
            location.reload();
          } catch (e) {
            console.log(e);
          }
        } else {
          _this.setStatus("> Bluetooth Device is already disconnected");
        }
      };

      start ? connect() : disconnect();
    },
    checkToken() {
      this.setStatus("Checking token...");
      this.busy = false;
      this.newCommand(6);
    },
    handleNotifications(event) {
      let value = event.target.value;
      let str = "";
      for (let i = 0; i < value.byteLength; i++) {
        str += String.fromCharCode(value.getUint8(i));
      }
      this.bleResponseLog = str;
      this.bleResponse += str.trim();

      try {
        let res = JSON.parse(this.bleResponseLog.replace(/\0/g, ""), true);
        if (res.status === 8) this.deviceConnected = true;
      } catch (e) {}

      console.log("Response: " + str);
    },
    onDisconnected() {
      this.connected = false;
      this.deviceDisconnected = true;
      this.setStatus(this.bleDevice ? this.bleDevice.name + " Disconnected." : "");
      this.setState(false);
    },
    sendToDevice(dataString) {
      if (this.bleDevice && this.bleDevice.gatt.connected) {
        this.setStatus("Send: " + dataString);
        let valArr = this.prepareString(dataString);
        this.rxCharacteristic.writeValue(valArr).then(() => {
          // console.log('Sent')
        });
      } else {
        // alert('Not connected to a device yet.')
      }
    },
    sendCommand(commandObj, timeout = 500) {
      this.bleBusy = true;
      this.bleResponse = "";
      this.bleResponseObj = "";
      this.error = false;

      this.setStatus("Communicating with device...");

      let _this = this;
      let string = JSON.stringify(commandObj);
      let command = this.splitString(string, 18);
      let newCommand = ["0"];
      command.forEach((command) => {
        newCommand.push("1" + command);
      });
      newCommand.push("2");

      console.log(newCommand);

      function start(counter) {
        if (counter < newCommand.length) {
          let proceed = true;
          setTimeout(() => {
            _this.bleResponse = "";
            if (newCommand[counter] !== "2") {
              try {
                if (_this.bleResponse) {
                  let res = JSON.parse(_this.bleResponse.replace(/\0/g, ""), true);
                  if (res.status !== 0) {
                    if (res.status === 8) {
                      _this.deviceConnected = true;
                    } else {
                      handleError(_this.responseCodes[res.status].message);
                    }
                    proceed = false;
                  }
                }
              } catch (e) {
                handleError();
                proceed = false;
                // console.log(e)
              }
            }
            if (proceed) {
              _this.sendToDevice(newCommand[counter]);
              counter++;
              start(counter);
            }
          }, timeout);
        } else {
          console.log("Done");
          done();
        }
      }

      function handleError(error) {
        this.alertMessage = error || "Could not complete request. Please try again.";
        this.bleBusy = false;
      }

      function done() {
        setTimeout(() => {
          _this.bleBusy = false;
          if (commandObj.command === 6) _this.busy = false;
          _this.lastCommand = commandObj;
          _this.getResponse(commandObj);
        }, 3000);
      }

      start(0);
    },
    getResponse(command) {
      this.$nextTick(() => {
        this.allowRetry = true;
        try {
          this.bleResponseObj = JSON.parse(this.bleResponse.replace(/\0/g, ""), true);
          let res = this.bleResponseObj;
          if (this.setupMode === null) {
            if (command.command === 6 && res.status === 0) {
              this.invalidToken = false;
            } else {
              this.invalidToken = true;
            }
          } else if (this.setupMode === "add") {
            if (command.command === 0 && res.status === 0) {
              this.addWifiSuccess = true;
              this.allowRetry = false;
              this.wifi = {
                ssid: "",
                security: null,
                encryption: 0,
                channel: null,
                password: "",
                password2: "",
              };
              this.$nextTick(() => {
                this.newCommand(1);
              });
            } else {
              this.alertMessage = this.responseCodes[res.status].message;
            }
          } else if (this.setupMode === "clear") {
            if (command.command === 3 && res.status === 0) {
              this.clearWifiSuccess = true;
              this.allowRetry = false;
            } else {
              this.alertMessage = this.responseCodes[res.status].message;
            }
          }
        } catch (e) {
          // console.log(e)
          this.bleError = "";
          if (command.command !== 6) this.alertMessage = "Could not complete request. Please try again.";
        }
      });
    },
    newCommand(commandEnum) {
      let command = {
        token: this.token,
        command: commandEnum,
      };
      this.sendCommand(command);
    },
    addWifi() {
      this.sendCommand(this.addWifiData);
      this.addWifiDialog = false;
    },
    showAddWifiDialog() {
      if (this.$refs.addWifiForm.validate()) {
        if (this.wifi.password !== this.wifi.password2) return false;
        this.addWifiDialog = true;
      }
    },
    clearWifi() {
      this.newCommand(3);
    },
    reset() {
      this.deviceDisconnected = false;
      this.deviceConnected = false;
      this.token = null;
      this.setup(false);
    },
    setState(enabled) {
      this.connected = enabled;
    },
    setStatus(status) {
      this.status = status;
      console.log(status);
    },
    splitString(string, size) {
      let re = new RegExp(".{1," + size + "}", "g");
      return string.match(re);
    },
    prepareString(s) {
      let valArr = new Uint8Array(s.length);
      for (let i = 0; i < s.length; i++) {
        let val = s[i].charCodeAt(0);
        valArr[i] = val;
      }
      return valArr;
    },
    retry() {
      this.alertDialog = false;
      if (this.lastCommand) {
        this.lastCommand.command === 0 ? this.sendCommand(this.lastCommand) : this.newCommand(this.lastCommand.command);
      }
    },
  },
  watch: {
    bleDevice() {
      console.log(this.blueDevice);
    },
    alertMessage() {
      if (this.alertMessage) this.alertDialog = true;
    },
  },
  // beforeMount () {
  //   if (!this.role=='owner') this.$router.push({ path: 'not-found'})
  // },
  mounted() {
    if (this.role == "owner") {
      this.setup(false);
    } else {
      this.$router.push({ path: "not-found" });
    }
    if (window.location.href.match(/localhost/)) {
      this.wifi = {
        ssid: "webwizzer_asus",
        security: 3,
        encryption: 0,
        channel: 0,
        password: "11112222",
        password2: "11112222",
      };

      this.token = "e29c0fcf998322e4384a2905880fd7c357491318df7022d3a1f025260fedc9e3";
    }
  },
};
</script>

<style lang="scss" scoped>
.setup-box {
  border: 1px solid #f2f2f2 !important;
}

.wifi-setup-button {
  max-width: 280px;
  margin-right: auto;
  margin-left: auto;
}

.status {
  font-size: 0.8em;
  background-color: #f4f4f4;
}

.bluefy {
  background: #f2f2f2;
}
</style>

<style lang="scss">
.setup-info-checkbox {
  .v-input__slot {
    align-items: start !important;
  }
}
</style>
