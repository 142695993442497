var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('RangeChoiceForm',{attrs:{"valueKey":'consumption'},on:{"back":function($event){return _vm.$emit('back')},"submit":function($event){return _vm.$emit('submit')}},scopedSlots:_vm._u([{key:"minInput",fn:function(ref){
var error = ref.error;
var submit = ref.submit;
var type = ref.type;
return [_c('v-text-field',{attrs:{"type":"number","label":"kWh","outline":"","autocomplete":"false","required":"","min":1,"rules":[_vm.validators.noDecimalNumber, _vm.validators.oneOrMore, function (v) { return type === 'between' && _vm.maxConsumption ? _vm.validators.leftNumber(v, _vm.maxConsumption) : true; }],"error":!!error},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return submit()}},model:{value:(_vm.minConsumption),callback:function ($$v) {_vm.minConsumption=$$v},expression:"minConsumption"}})]}},{key:"maxInput",fn:function(ref){
var error = ref.error;
var submit = ref.submit;
var type = ref.type;
return [_c('v-text-field',{attrs:{"type":"number","label":"kWh","outline":"","autocomplete":"false","required":"","min":1,"rules":[_vm.validators.noDecimalNumber, _vm.validators.oneOrMore, function (v) { return type === 'between' && _vm.maxConsumption ? _vm.validators.rightNumber(v, _vm.minConsumption) : true; }],"error":!!error},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return submit()}},model:{value:(_vm.maxConsumption),callback:function ($$v) {_vm.maxConsumption=$$v},expression:"maxConsumption"}})]}}]),model:{value:(_vm.inputs),callback:function ($$v) {_vm.inputs=$$v},expression:"inputs"}})}
var staticRenderFns = []

export { render, staticRenderFns }