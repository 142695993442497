import Vue from "vue"

export function timeBasedParseConditionToInputs({ condition = {}, type }) {
  let parsedValue = {};
  switch (type) {
    case "time":
      let maxHour = condition.maxHour;
      let minHour = condition.minHour;
      maxHour > 12 ? maxHour -= 12 : (maxHour === 0 ? maxHour = 12 : maxHour = condition.maxHour)
      minHour > 12 ? minHour -= 12 : (minHour === 0 ? minHour = 12 : minHour = condition.minHour)
      parsedValue = {
        maxHour: maxHour ? (maxHour < 10 ? "0" + maxHour : maxHour.toString()) : "00",
        minHour: minHour ? (minHour < 10 ? "0" + minHour : minHour.toString()) : "00",
        maxMinute: condition.maxMinute ? (condition.maxMinute < 10 ? "0" + condition.maxMinute : condition.maxMinute.toString()) : "00",
        minMinute: condition.minMinute ? (condition.minMinute < 10 ? "0" + condition.minMinute : condition.minMinute.toString()) : "00",
        minAmPm: condition.minHour > 11 ? "PM" : "AM",
        maxAmPm: condition.maxHour > 11 ? "PM" : "AM",
      };
      break;
    case "date":
      const current_year = "2040";
      parsedValue = {
        tab: 0,
        fromRawDate: Vue.moment(current_year + "-" + condition.minMonth + "-" + condition.minDay).format("YYYY-MM-DD"),
        toRawDate: Vue.moment(current_year + "-" + condition.maxMonth + "-" + condition.maxDay).format("YYYY-MM-DD"),
        maxDay: condition.maxDay,
        minDay: condition.minDay,
        maxMonth: condition.maxMonth,
        minMonth: condition.minMonth,

        // these are constants values
        minHour: 0,
        minMinute: 0,
        maxHour: 23,
        maxMinute: 59,
      };
      break;
    case "day":
      parsedValue = {
        minWeekday: condition.minWeekday,
        // maxWeekday: (condition.maxWeekday + 6) % 7,
        maxWeekday: condition.maxWeekday % 7,

        // these are constants values
        minHour: 0,
        minMinute: 0,
        maxHour: 23,
        maxMinute: 59,
      };
      break;
  }
  return parsedValue;
}

export function timeBasedAddUnless(indexes, unless, new_unless) {
  if (unless.length == 0) return { unless: [new_unless], index: 0 };
  let newIndex = null;

  if (indexes.length == 0) {
    unless.push(new_unless);
    newIndex = unless.length - 1;
    return { unless: unless, index: newIndex };
  }

  const index = indexes[0];
  indexes.shift();
  if (!unless[index].plan.hasOwnProperty("unless")) unless[index].plan.unless = [];
  if (indexes.length > 0) {
    const returns = timeBasedAddUnless(indexes, unless[index].plan.unless, new_unless);
    unless[index].plan.unless = returns.unless;
    newIndex = returns.index;
  } else {
    unless[index].plan.unless.push(new_unless);
    newIndex = unless[index].plan.unless.length - 1;
  }
  return { unless: unless, index: newIndex };
}

export function timeBasedParseUnless(indexes, unless, payload) {
  const index = indexes[0];
  indexes.shift();
  if (indexes.length > 0) {
    unless[index].plan.unless = timeBasedParseUnless(indexes, unless[index].plan.unless, payload);
    return unless;
  }
  if (payload.hasOwnProperty("condition")) unless[index].condition = payload.condition;
  if (payload.hasOwnProperty("disabled") && payload.disabled) {
    unless[index].plan = unless[index].plan.unless ? { unless: unless[index].plan.unless, disabled: true } : { disabled: true };
    return unless;
  }
  if (unless[index].plan.hasOwnProperty("disabled")) delete unless[index].plan.disabled;
  if (payload.hasOwnProperty("startSessionCost")) unless[index].plan.startSessionCost = payload.startSessionCost ? parseFloat(payload.startSessionCost) : 0;
  if (payload.hasOwnProperty("whRate")) unless[index].plan.whRate = payload.whRate ? parseFloat(payload.whRate) / 1000 : 0;
  if (payload.hasOwnProperty("hRate")) unless[index].plan.hRate = payload.hRate ? parseFloat(payload.hRate) : 0;
  return unless;
}

export function timeBasedPrepareUnless({ condition, isEdit = false, inputs }) {
  if (!isEdit) {
    const new_unless = {
      condition: condition,
      plan: {
        startSessionCost: null,
        whRate: null,
        hRate: null,
      },
    };
    const unlessReturns = timeBasedAddUnless(JSON.parse(JSON.stringify(inputs.conditional.unless_indexes)), inputs.conditional.unless, new_unless);
    inputs.conditional.unless = unlessReturns.unless;
    inputs.conditional.unless_indexes.push(unlessReturns.index);
  } else {
    inputs.conditional.unless = timeBasedParseUnless(JSON.parse(JSON.stringify(inputs.conditional.unless_indexes)), inputs.conditional.unless, { condition: condition });
  }
  return inputs;
}