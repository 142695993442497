<template>
  <div>
    <v-card-text>
      <v-flex xs12>
        <v-layout row xs12 class="pa-2" wrap justify-space-around>
          <SelectableCards 
            :items="items" 
            :itemContainerClasses="cardClasses"
            :disabled="!allowAccess"
          />
        </v-layout>
      </v-flex>
    </v-card-text>
    <v-card-actions class="pa-3 flex">
      <v-btn color="gray" flat="flat" @click="$emit('back')">
        Back
      </v-btn>
    </v-card-actions>
  </div>
</template>

<script>
import SelectableCards from '@components/SelectableCards/SelectableCards';
import { OwnerProxyHelpers } from '@/library/helpers';

export default {
  name: "PaymentPlanChoiceForm",
  components: { SelectableCards },
  props: {
    items: {
      required: true,
      type: Array
    },
    cardClasses: {
      type: Array
    }
  },
  computed: {
    allowAccess() {
      return OwnerProxyHelpers.isHaveAccessToFeature("PAYMENT_PLAN_CREATE") || OwnerProxyHelpers.isHaveAccessToFeature("PAYMENT_PLAN_EDIT")
    }
  }
}
</script>